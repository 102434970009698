// Request.js
import React from 'react';
import ItemImage from '../items/itemTools/ItemImage';
import { useLocation } from 'react-router-dom';
import TransferRequestStatus from './TransferRequestStatus';

const RenderItemTransferRequest = ({ itemTransferRequest }) => {

  const location = useLocation();
  let linkNeeded = true;

  if(location.pathname.includes("/organization/itemTransferRequest/")) {
    linkNeeded = false;
  }

  return (
    <div className="card rounded-4 mb-3 bg-light-grey">
      <div className={`card-body rounded-4 m-0 p-0 bg-light-grey`}>
        <a href={linkNeeded ? `/item/${itemTransferRequest.itemId}/requests` : null}
             className="text-decoration-none text-dark" >
          <div className='row m-0 p-0'>
            <div className='col-6 justify-content-center'>
              <ItemImage photo={{fileName: itemTransferRequest.itemImageUrl}} /> 
            </div>
            <div className='col-5'>
              {itemTransferRequest.decision === null ? (
                <div className='row mt-2'>
                  <div className='col-12 m-1'> 
                    <i className="bi bi-clipboard-check h6"> </i>
                    <span>{itemTransferRequest.itemName}</span>
                  </div>
                  <div className='col-12 m-1'>
                    <i className="bi bi-person h6"> </i>
                    <span>{itemTransferRequest.receiverName}</span>
                  </div>
                  <div className='col-12 m-1'>
                    <i className="bi bi-person-check h6"> </i>
                    <span>{itemTransferRequest.managerName}</span>
                  </div>
                </div>
              ) : (
                <div className='row mt-2'>
                  <div className='col-12 m-1'> 
                    <i className="bi bi-clipboard-check h6"> </i>
                    <span>{itemTransferRequest.itemName}</span>
                  </div>
                  <div className='col-12 m-1'>
                    <i className="bi bi-person h6"> </i>
                    <span>{itemTransferRequest.receiverName}</span>
                  </div>
                  <div className='col-12 m-1'>
                    <i className="bi bi-person-check h6"> </i>
                    <span>{itemTransferRequest.managerName}</span>
                  </div>    
                  <div className='col-12 m-1'>
                    <i className="bi bi-calendar-date h6"> </i>
                    <span>{new Date(itemTransferRequest.creationDate).toLocaleString()}</span>
                  </div>
                </div>
              )}
            </div>
            <TransferRequestStatus status={itemTransferRequest.decision} />
          </div>
        </a>
      </div>
    </div>
  );
};

export default RenderItemTransferRequest;