import React, { useState } from "react";
import ModalSelector from "./components/ModalSelector";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import LanguageInNavBarToggler from "./components/LanguageInNavBarToggler";
import { isUserInOrganization } from "./user/UserRoleChecks";

const NavBar = ({isLogged, onLogout}) => {
    const [isFilterSelectorActive, setFilterSelectorActive] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    function handleLogout() {
        if(onLogout != null) {
            onLogout();
        }
        navigate("/");
    }

    function openCategorySelector() {
        setFilterSelectorActive(true);
    }

    function closeCategorySelector() {
        setFilterSelectorActive(false);
    }

    return (
        <nav className="navbar bg-dark border-bottom border-body navbar-expand fixed-top" style={{'height': '50px'}} data-bs-theme="dark">
            <div className="container" style={{'maxWidth': '700px'}}>
                <a className="navbar-brand" href="/">My items</a> 
                <LanguageInNavBarToggler/>
                {isLogged ? 
                    <div className="navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item">
                                <div className={"nav-link "  + (location.pathname.includes("/page/") && "active")} 
                                     style={{ cursor: 'pointer' }} onClick={openCategorySelector}>
                                    <i className="bi bi-search h5" title={t('action.filter')}></i>
                                </div>
                            </li>
                            {isUserInOrganization() && 
                                <>
                                <li className="nav-item">
                                    <a className={"nav-link "  + (location.pathname.includes("/organization/itemTransferRequestList") && "active")}
                                        href="/organization/itemTransferRequestList/active/1">
                                        <i className="bi bi-card-checklist h5" title={t('organization.requests')}></i>
                                    </a>
                                </li>
                                </>
                            }
                            <li className="nav-item">
                                <a className={"nav-link "  + (location.pathname.includes("/configuration") && "active")} 
                                   href="/configuration/personal/info">
                                    <i className="bi bi-gear h5" title={t('action.settings')}></i>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className={"nav-link "  + (location.pathname.includes("/information") && "active")} 
                                   href="/information">
                                    <i className="bi bi-info-circle h5" title={t('label.how.to')}></i>
                                </a>
                            </li>
                            <li className="nav-item">
                                <div className="nav-link" style={{ cursor: 'pointer' }} onClick={handleLogout}> 
                                    <i className="bi bi-box-arrow-right h5" title={t('action.logout')}></i>
                                </div>
                            </li>
                        </ul>
                    </div>
                    :
                    <>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item">
                                <a className={"nav-link "  + (location.pathname.includes("/login") && "active")} 
                                   href="/login" aria-disabled="false"> 
                                   <i className="bi bi-box-arrow-in-right h5" title={t('action.login')}></i>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className={"nav-link "  + (location.pathname.includes("/information") && "active")} 
                                   href="/information">
                                    <i className="bi bi-info-circle h5" title={t('label.how.to')}></i>
                                </a>
                            </li>
                            {/*<li className="nav-item">
                                <a className="nav-link" aria-current="page" href="/registration">{t('action.registration')}</a>
                            </li> */}
                        </ul>
                        
                    </div>
                    </>
                    }
                {isLogged && 
                    <>
                    <ModalSelector  
                        isOpen={isFilterSelectorActive} 
                        onClose={closeCategorySelector} /> 
                    </>
                }
            </div>
        </nav>
    );
};

export default NavBar;