import React from "react";
import { useTranslation } from 'react-i18next';

function ItemServicability ({serviceable, isEditing, onChange}) {
    const { t } = useTranslation();

    function onConditionChange(event) {
        if(onChange) {
            onChange(parseInt(event.target.value));
        }
    }

    var serviceabilityStyle = "badge ";
    var serviceabilityText;
    var nullInput = serviceable == null;

    switch(serviceable) {
        case 0:
            nullInput = true;
            break;

        case 1:
            serviceabilityText = t('condition.works');
            serviceabilityStyle += "bg-success-subtle border border-success-subtle  text-success-emphasis";
            break;

        case 2:
            serviceabilityText = t('condition.repair.needed');
            serviceabilityStyle += "bg-warning-subtle border border-warning-subtle  text-warning-emphasis";
            break;

        case 3:
            serviceabilityText = t('condition.broken');
            serviceabilityStyle += "bg-danger-subtle border border-danger-subtle text-danger-emphasis";
            break;

        default:
            break;
    }

    return (
        <>
            {isEditing ? 
            <div className="col-auto input-group m-2 pe-3">
                <i className="bi bi-wrench h5 p-1"> </i>
                <select className="form-select" aria-label={t('item.condition')} 
                        name="stateOfServiceability" onChange={onConditionChange}
                        defaultValue={serviceable}>
                  <option value="1">{t('condition.works')}</option>
                  <option value="2">{t('condition.repair.needed')}</option>
                  <option value="3">{t('condition.broken')}</option>
                </select>
            </div> : <>{!nullInput && (   
                <div className="d-inline-block pt-0 mx-2" style={{'verticalAlign': 'top'}}>
                    <sup><span className={serviceabilityStyle}> {serviceabilityText} </span></sup>
                </div>
                
                )}</>
            }
            
        </>
    );
}

export default ItemServicability;