import React, { useState } from "react";
import backendHost from "../backendTools/backendHost";
import MyItemsAxios from "../backendTools/MyItemsAxios";

const InviteMember = () => {

    const [isMailValid, setMailValid] = useState(true);
    const [alertMessage, setAlertMessage] = useState(null);

    async function handleInvite(event) {
        event.preventDefault();
        const inputMail = document.getElementById("email").value;
        const inputName = document.getElementById("name").value;
        const userMail = inputMail.trim();
        const userName = inputName.trim();
        if(userMail && userName) {
            const inviteForm = {
                email               : userMail,
                username            : userName,
            };
            await MyItemsAxios.post(`${backendHost}/organization/manager/invite-member`, inviteForm)
                .catch(function (error) {
                    if (error.response) {
                        setAlertMessage(error.response.data);
                    }
                }).then(setAlertMessage("Invite been sent"));
        }
    }

    function isEmailValid(email) {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }

    function validateEmail(event) {
        const newMailValue = event.currentTarget.value.trim();
        if(newMailValue !== '') {
            var isMailValid = isEmailValid(newMailValue);
            setMailValid(isMailValid);
        } else {
            setMailValid(true);
        }
    }

    return (
        <tr>
            <td className="my-0"> 
                <input  type="text"
                        id="name"
                        className="form-control"
                        placeholder="Username"/>
                {!isMailValid && <p style={{ color: 'red' }}>Invalid Email</p>}
            </td>
            <td className="my-0"> 
                <input  type="text" 
                        id="email"
                        className="form-control" 
                        placeholder="E-mail"
                        onBlur={validateEmail}/>
                {!isMailValid && <p style={{ color: 'red' }}>Invalid Email</p>}
            </td>
            <td> - - - </td>
            <td> - - - </td>
            <td> 
                <button type="submit"  className="form-control btn btn-outline-secondary" onClick={handleInvite}> Invite </button>
            </td>
        </tr>
    );
}

export default InviteMember;
