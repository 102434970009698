// RequestsList.js
import React, { useEffect, useState } from 'react';
import { getManagerItemTransferRequestHistory, getManagerItemTransferRequests, getPersonalItemTransferRequestHistory, getPersonalItemTransferRequests } from '../backendTools/ItemTransferRequestsService';
import RenderItemTransferRequest from '../components/RenderItemTransferRequest';
import PageScroll from '../components/PageScroll';
import { useParams } from 'react-router-dom';
import ItemTransferRequestTabs, { subTabsSet, mainTabsSet } from '../components/ItemTransferRequestListTabs';

const pagePath = "/organization/itemTransferRequestList/";

const ItemTransferRequestsList = () => {
  const { pageMode, pageNumber } = useParams();

  const [requestPageNumber, setRequestPageNumber] = useState(parseInt(pageNumber) - 1);
  const [displayItemTransferRequests, setDisplayItemTransferRequest] = useState([]);
  const [displayListPaging, setDisplayListPaging] = useState(null);
  const [requestsTypeManager, setRequestsTypeManager] = useState(() => {
    return sessionStorage.getItem("requestsType") || subTabsSet.SENT.path;  //show personal requests by default
  });

  function setRequestsType(type) {
    sessionStorage.setItem("requestsType", type);
    setRequestsTypeManager(type);
  }

  useEffect(() => {
    async function fetchItemTransferRequests () {
      if(requestsTypeManager === subTabsSet.RECEIVED.path) {
        if(pageMode === mainTabsSet.PENDING.path) {
          const itemTransferRequests = await getManagerItemTransferRequests(requestPageNumber);
          setDisplayItemTransferRequest(itemTransferRequests.content);
          setDisplayListPaging(itemTransferRequests.page);
        } else {
          const itemTransferRequests = await getManagerItemTransferRequestHistory(requestPageNumber);
          setDisplayItemTransferRequest(itemTransferRequests.content);
          setDisplayListPaging(itemTransferRequests.page);
        }
      } else {
        if(pageMode === mainTabsSet.PENDING.path) {
          const itemTransferRequests = await getPersonalItemTransferRequests(requestPageNumber);
          setDisplayItemTransferRequest(itemTransferRequests.content);
          setDisplayListPaging(itemTransferRequests.page);
        } else {
          const itemTransferRequests = await getPersonalItemTransferRequestHistory(requestPageNumber);
          setDisplayItemTransferRequest(itemTransferRequests.content);
          setDisplayListPaging(itemTransferRequests.page);
        }
      }
    };
    fetchItemTransferRequests();
  }, [requestsTypeManager, pageMode]);

  function switchTab(tabName) {
    if(tabName === subTabsSet.SENT.path || tabName === subTabsSet.RECEIVED.path) {
      setRequestPageNumber(0);
      switchMode(tabName);
    } else {

    }
  }

  function switchMode(selectedMode) {
    setRequestsType(selectedMode);
  }


  return (
    <div className="container mt-5 p-3" style={{ 'maxWidth': '700px' }}>
      <ItemTransferRequestTabs onTabChange={switchTab} requestsMode={pageMode} requestsType={requestsTypeManager} />
      <PageScroll totalPages={displayListPaging?.totalPages} currentPage={requestPageNumber + 1} query={""} defaultPath={pagePath + pageMode + '/' }/>
          {displayItemTransferRequests.length ? 
            displayItemTransferRequests.map((request) => (
              <RenderItemTransferRequest 
                key={request.id} 
                itemTransferRequest={request}  /> ))
            : (<div className='row'> 
                  <h5></h5> 
              </div> )
          }
    </div>
  );
};

export default ItemTransferRequestsList;