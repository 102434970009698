import React, { useContext, useEffect, useState } from "react";
import {Container, Row, Col } from 'react-bootstrap';
import MyItemsAxios from "./backendTools/MyItemsAxios";
import Statistics from "./components/Statistics"
import RenderListItems from "./RenderListItems";
import { ApplicationContext } from "./App";
import { getConfigStorage } from "./user/ConfigStorage";
import { isUserInOrganization } from "./user/UserRoleChecks";
import AnyPageIconTextLinkTabs from "./components/AnyPageIconTextLinkTabs";
import { organizationTabsSet } from "./components/ConfigurationPageTabs";

const itemsLimit = getConfigStorage().mainPageSize;
const requestSearchUtl = "/organization/item/search?page-number=0&page-size=" + itemsLimit + "&category=&location-id=&condition=&start-created-date=&end-created-date=&start-updated-date=&end-updated-date=";

const addItemTab = {
    path : '/item/0/edit',
    translateTag : 'item.add.new',
    iconBi : 'bi-clipboard-check',
    noPrefix : true
  };
  
const MainPage = ({}) => {

    const {userCategories, userLocations} = useContext(ApplicationContext);
    const [itemsPagingData, setItemsPagingData] = useState(null);

    const organizationTabs = Object.values(organizationTabsSet);
    const mainPageTabs = [addItemTab, ...organizationTabs];

    useEffect(() => {
    
        // Function to fetch data
        const fetchData = async () => {
          try {
            const response = await MyItemsAxios.get(requestSearchUtl);
            setItemsPagingData(response.data);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
      }, []); 

    if(userCategories === undefined || userLocations === undefined) 
        return (<div> </div>);

    return (
        <Container className="pt-4 mt-4" style={{"maxWidth":'700px'}}>
            {userLocations !== undefined &&
            <Row>
                <Col xs={12}>
                    {isUserInOrganization() ? 
                        (<AnyPageIconTextLinkTabs 
                            tabsSet={mainPageTabs}
                            linkPrefix={'/configuration/organization'}/>) 
                        : 
                        (<Statistics />)
                    }
                </Col>
                <Col xs={12}>
                    { itemsPagingData && 
                        <RenderListItems 
                        itemsPagingData={itemsPagingData} 
                        categoriesList={userCategories} 
                        locationsList={userLocations} />
                    }
                </Col>
            </Row>
            }
        </Container>
    );
}

export default MainPage;