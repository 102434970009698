import React from "react";

function ItemAssignee ({itemUser, itemUserId}) {
  
  return (
    <>
      { (itemUser !== null && itemUser !== '') && (itemUserId ? (
        <a  href={`/page/1?item-user=${itemUserId}`} 
            className="link-body-emphasis link-offset-2 link-underline-opacity-50 link-underline-opacity-75-hover col-auto m-2">  
          <i className="bi bi-person"> </i>
          <span> {itemUser} </span>
        </a>
      ) : (
        <div className="col-auto m-2">  
          <i className="bi bi-person"> </i>
          <span> {itemUser} </span>
        </div>
      ))}
    </>
  );
}

export default ItemAssignee;
