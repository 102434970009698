import React, { useEffect, useState } from "react";
import MyItemsAxios from "../backendTools/MyItemsAxios";
import UserButtons from "./UserButtons";
import ModalConfirm from "../components/ModalConfirm";
import organizationRoles, {getOrganizationRolesArray} from "../components/roles/OrganizationRoles";
import { isUserManagerOrOwner } from "../user/UserRoleChecks";
import InviteMember from "./InviteMember";
import { getUserName } from "../user/UserStorage";

export async function getUsers() {
    try {
        const allUsersResponse = await MyItemsAxios.get('/organization/get-all-users');
        return allUsersResponse.data;
    } catch (error) {
        console.error('Error fetching users:', error);
    }
}

const MemberList = () => {

    const [usersList, setUserList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalOnConfirm, setModalOnConfirm] = useState(null);
    const [modalOnDecline, setModalOnDecline] = useState(null);

    useEffect(() => {
        function processUsers (users) {
            const organizationRoles = getOrganizationRolesArray();
            return users.map(user => {
                let userRole = organizationRoles.find(organizationRole => user.roles.includes(organizationRole));
                user.roles = userRole;
                return user;
            }).sort((a, b) => organizationRoles.indexOf(a.roles) - organizationRoles.indexOf(b.roles))
        }
        
        getUsers().then(processUsers).then(setUserList);
    }, []);

    function deactivateUser(userId) {
        return MyItemsAxios.put('/organization/manager/' + userId + '/deactivate');
    }

    function activateUser(userId) {
        return MyItemsAxios.put('/organization/manager/' + userId + '/activate');
    }

    function promoteUser(userId, role) {
        return MyItemsAxios.put('/organization/owner/' + userId + '/role/' + role);
    }

    function handleBlockButton(userId) {
        const userIndex = usersList.findIndex(user => user.id === userId);
        if (userIndex != -1) {
            const actionText = usersList[userIndex].activated ? "deactivate" : "activate";
            setModalMessage(`Are you sure you want to ${actionText} this user?`);
            setModalOnConfirm(() => async () => {
                let response;
                try {
                    if (usersList[userIndex].activated) {
                        response = await deactivateUser(userId);
                    } else {
                        response = await activateUser(userId);
                    }

                    if(response.status === 200) {
                        setShowModal(false);
                        window.location.reload();
                    }
                } catch (error) {
                    console.error(error);
                }
            });
            setModalOnDecline(() => () => setShowModal(false));
            setShowModal(true);
        }
    }

    function handlePromote(userId, newRole) {
        const userIndex = usersList.findIndex(user => user.id === userId);
        if (userIndex != -1) {
            setModalMessage(`Are you sure in setting role ${newRole} for this user: ${usersList[userIndex].username}?`);
            setModalOnConfirm(() => async () => {
                let response;
                try {
                    response = await promoteUser(userId, newRole);

                    if(response.status === 200) {
                        setShowModal(false);
                        window.location.reload();
                    }
                } catch (error) {
                    console.error(error);
                }                
            });
            setModalOnDecline(() => () => setShowModal(false));
            setShowModal(true);
        }
    }

    function getRoleClass(user) {
        if(user.username === getUserName()) {
            return 'table-user';
        } else {
            return null;
        }
    }

return (
    <>
        <table className="table table-stripped table-secondary table-borderless my-3">
            <thead>
                <tr>
                    <td> Username </td>
                    <td> E-mail </td>
                    <td> Role </td>
                    <td> Status </td>
                    <td> Actions </td>
                </tr>
            </thead>
            <tbody>
            {isUserManagerOrOwner() && <InviteMember/> }
            {usersList && usersList.map((user, index) => (
                <tr key={index} className={getRoleClass(user)}>
                    <td> {user.username} </td>
                    <td> {user.email} </td>
                    <td> {user.roles} </td>
                    <td> {user.activated ? "Activated" : "Invited"} </td>
                    <td> <UserButtons   currentUser={user} 
                                        userActivationControl={handleBlockButton}
                                        changeRole={handlePromote}/> 
                    </td>
                </tr>
            )) }
            </tbody>
        </table>
        <ModalConfirm
            message={modalMessage}
            isOpen={showModal}
            onConfirm={modalOnConfirm}
            onDecline={modalOnDecline}
        />
    </>
);
}

export default MemberList;