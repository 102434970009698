import React, { useEffect, useState } from "react";

const delta = 2; // Количество страниц слева и справа от текущей
const startShiftLimit = delta+1;
const maxConsistentPagesNumber = delta * 2 + 1;

const PageScroll = ({totalPages, currentPage, query, defaultPath}) => {

  const buttonStyle = '';
  const activeStyle = 'active';

  const [showPages, setShowPages] = useState([]); 
  const [firstNeeded, setFirstNeeded] = useState(false);
  const [lastNeeded, setLastNeeded] = useState(false);

  const getPages = () => 
  {
    const pages = [];

    if (totalPages <= maxConsistentPagesNumber) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      } 
    } else {
   
      if (currentPage > startShiftLimit) {
        pages.push("...");
        setFirstNeeded(true);
      } else {
        pages.push(1, 2);
      };

      let i = Math.max(3, currentPage - delta);
      while (i <= Math.min(totalPages - delta, currentPage + delta)) {
        pages.push(i);
        i++;
      }

      if (currentPage < totalPages - delta) {
        pages.push("...");
        setLastNeeded(true);
      } else {
        pages.push(totalPages - 1, totalPages);
      }
    }

    return pages;
  };

  useEffect( ()=> {
    if(totalPages !== undefined && totalPages !== 0) {
      setShowPages(getPages());
    }
  }, [totalPages]);

  if(totalPages < 2) {
    return;
  }

  return (
    <nav aria-label="Page navigation">
      <ul className="pagination justify-content-center">

        { firstNeeded && (
        <li className="page-item">
        <a  className={"page-link " + (1 === currentPage ? activeStyle : buttonStyle)}
            href={defaultPath + 1 + (query ? ('?'+query) : (''))} 
            disabled={currentPage === 1}>
          1
        </a>
      </li> )}

      {showPages?.map((page, index) =>
        page === "..." ? (
          <li className="page-item" key={index}>
            <span className="dots">...</span>
          </li>
        ) : (
          <li className="page-item" key={index}>
            <a  href={defaultPath + page + (query ? ('?'+query) : (''))} 
                className={"page-link " + (page === currentPage ? activeStyle : buttonStyle)}> 
                {page} 
            </a>  
          </li>
                   
        )
      )}

      {lastNeeded && (
      <li className="page-item">
      <a  href={defaultPath + totalPages + (query ? ('?'+query) : (''))} 
            className={"page-link " + (totalPages === currentPage ? activeStyle : buttonStyle)}
            disabled={currentPage === totalPages}> 
          {totalPages}
        </a> 
      </li>)}
      
      </ul>
    </nav>
  );
};

export default PageScroll;
