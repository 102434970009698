//using localStorage to keep user data
const UserStorageName = 'userStatus';

export function setUserData(incomeJSON) {
    localStorage.setItem(UserStorageName, JSON.stringify(incomeJSON));
}

export function clearUserData() {
    localStorage.removeItem(UserStorageName);
}

export function getUserData() {
    const userData = localStorage.getItem(UserStorageName);
    if(userData) {
       return JSON.parse(userData); 
    } else {
        console.error("Userdata not found in local storage");
    }
}
export function getUserName() {
    const userData = localStorage.getItem(UserStorageName);
    if(userData) {
       return JSON.parse(userData).username; 
    } else {
        console.error("Username not found in local storage");
        return null;
    }
}

export function getUserRoles() {
    const userData = localStorage.getItem(UserStorageName);
    if(userData) {
        return JSON.parse(userData).roles;
    } else {
        console.error("Roles not found in local storage");
    }
}

export function getUserEmail() {
    const userData = localStorage.getItem(UserStorageName);
    if(userData) {
        return JSON.parse(userData).email;
    } else {
        console.error("Email not found in local storage");
    }
}

export function getUserOrganization() {
    const userData = localStorage.getItem(UserStorageName);
    if(userData) {
        return JSON.parse(userData).organizationName;
    } else {
        console.error("Organization name not found in local storage");
    }
}
