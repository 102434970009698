
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import QrGenerator from './QrGenerator';

function ItemQrCode({itemID, itemName}) {

  const { t } = useTranslation();

  const qrRef = useRef(null);

  const handleDownload = () => {
    const canvas = qrRef.current.querySelector("canvas");
    const url = canvas.toDataURL("image/png");
    const a = document.createElement("a");
    a.href = url;
    a.download = `qrcode-${itemName}.png`;
    a.click();
  }
  
  return (
    <div className='m-5'>
      <QrGenerator value={`https://my-items.net/item/${itemID}`} qrRef={qrRef} clickHandler={handleDownload}/>
    </div>
  );
}

export default ItemQrCode;