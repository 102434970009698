const systemRoles = {
    USER: 'USER',
    TEMPADMIN: 'TEMPADMIN',
    ADMIN: 'ADMIN'
};

export function getSystemRolesArray() {
    return Object.values(systemRoles);
}

export default systemRoles;
