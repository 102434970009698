import { useEffect, useState } from "react";
import ItemTransferRequestDecisionMaker from "../../components/ItemTransferRequestDecisionMaker";
import { getItemTransferRequest, getItemTransferRequestByItemId, updateItemTransferRequest } from "../../backendTools/ItemTransferRequestsService";
import ModalConfirm from "../../components/ModalConfirm";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ItemTransferRequests = ({itemId, requestId}) => {

    const [transferRequests, setTransferRequests] = useState([]);
    const [showActionButtons, setShowActionButtons] = useState(false); 

    const [modalConfirmOpen, setModalConfirmOpen] = useState(false);
    const [modalConfirmMessage, setModalConfirmMessage] = useState('');
    const [modalConfirmData, setModalConfirmData] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();
    const {t} = useTranslation();

    useEffect(()=> {
        async function receiveRequests() {
            var requests = await getItemTransferRequestByItemId(itemId);
            setTransferRequests(requests);
        }

        async function receiveSingleRequest() {
            var request = await getItemTransferRequest(requestId);
            setTransferRequests([request]);
        }

        if(requestId) {
            receiveSingleRequest();
            setShowActionButtons(true);
        } else {
            receiveRequests();
            setShowActionButtons(false);
        }

    }, [itemId, requestId]);

    async function handleItemTransferDecision (itemTransferRequestId, itemTransferRequestAction) {
        setModalConfirmOpen(true);
        const selectedRequest = transferRequests.find( request => request.id === itemTransferRequestId);
        setModalConfirmMessage(`${t(itemTransferRequestAction ? 'transfer.request.approve' : 'transfer.request.decline')}${t('transfer.request.prompt')}${selectedRequest.receiverName}?`);
        setModalConfirmData({itemTransferRequestId: itemTransferRequestId, action: itemTransferRequestAction});    
    };

    function handleModalDecline() {
        setModalConfirmOpen(false);
        setModalConfirmData({itemTransferRequestId: 0, action: null}); 
    }
    
    async function handleModalAccept() {
        setModalConfirmOpen(false);
        try {
            await updateItemTransferRequest(modalConfirmData.itemTransferRequestId, modalConfirmData.action);
        } catch (error) {
            console.error('Error performing action:', error);
        }
        navigate(`/item/${itemId}/requests`, { state: { from: location.pathname } });
    }


    return (
        <div className="row m-0">
        {transferRequests.map( (request, index) => (
            <ItemTransferRequestDecisionMaker 
                itemTransferRequest={request} 
                onAction={handleItemTransferDecision} 
                showButtons={showActionButtons}
                key={index}/>
        ))}
        <ModalConfirm isOpen={modalConfirmOpen} 
            message={modalConfirmMessage}
            onConfirm={handleModalAccept}
            onDecline={handleModalDecline}/> 
        </div>
    );

}

export default ItemTransferRequests;