import React from "react";
import organizationRoles from "../components/roles/OrganizationRoles";
import { isUserManagerOrOwner, isUserOwner } from "../user/UserRoleChecks";
import { getUserName } from "../user/UserStorage";

const UserButtons = ({ currentUser, userActivationControl, changeRole }) => {

    function handleBlockButton() {
        userActivationControl(currentUser.id);
    }

    function handleRoleChange (role) {
        changeRole(currentUser.id, role);
    };

    return (
        <>
        { currentUser.roles !== organizationRoles.OWNER && isUserManagerOrOwner() && currentUser.username !== getUserName() &&
        <div className="dropdown">
            <button
                className="btn btn-secondary dropdown-toggle"    //{activeStatus ? "btn btn-warning dropdown-toggle" : "btn btn-success dropdown-toggle"}
                type="button"
                id={"dropdownMenuButton" + currentUser.id}
                aria-haspopup="true" aria-expanded="false"
                data-bs-toggle="dropdown"
            >
                Actions
            </button>
            <div className="dropdown-menu" aria-labelledby={"dropdownMenuButton" + currentUser.id}>
                <button
                    className="dropdown-item"
                    onClick={() => handleBlockButton()}
                >
                    {currentUser.activated ? "Deactivate" : "Activate"}
                </button>
                { currentUser.roles === organizationRoles.MANAGER && isUserOwner() &&
                    <button
                        className="dropdown-item"
                        onClick={() => handleRoleChange(organizationRoles.MEMBER)}
                    >
                        Change to Member
                    </button>
                }
                { currentUser.roles === organizationRoles.MEMBER && isUserOwner() &&
                    <button
                        className="dropdown-item"
                        onClick={() => handleRoleChange(organizationRoles.MANAGER)}
                    >
                        Change to Manager
                    </button>
                }
                
            </div>
        </div>
    }</>
    );
};

export default UserButtons;