import React, { useEffect, useState } from "react";
import getCategoryBranch from "../../categoryTools/CategoryBranch";
import { Tree } from "antd";

function ItemCategoriesTree ({categoriesOfItem, categoryList}) {
    const [categoriesMap, setCategoriesMap] = useState(null);

    function addChildCategoryKey(categoryChild, parentKey, index) {
        const currentKey = `${parentKey}-${index}`
        categoryChild.key = currentKey;
        categoryChild.title = categoryChild.name;
        if(categoryChild.child) {
            categoryChild.children = [ addChildCategoryKey(categoryChild.child, currentKey, index) ];
        }
        return categoryChild;
    }

    function addKeysToCategories(categories) {
        var keyedCategories = [];
        categories.forEach( (category, index) => {
            const currentKey = `${index}`
            category.key = currentKey;
            category.title = category.name;
            if(category.child) {
                category.children = [ addChildCategoryKey(category.child, currentKey, index) ];
            }
            keyedCategories.push(category);
        })
        return keyedCategories;
    }

    useEffect( () => {
        function compareChilds(a, b) {
            if(a.id < b.id) return -1;
            if(a.id > b.id) return 1;
            if(a.child != null && b.child != null) {
                return compareChilds(a.child, b.child);
            } else {
                if(a.child != null) return 1;
                if(b.child != null) return -1;
            }
        }
        
        if(categoryList !== null && categoryList !== undefined && categoriesOfItem !== null) {
            var parsedCategories = [];
            categoriesOfItem.forEach(categoryId => {
                var category = getCategoryBranch(categoryList, categoryId);
                parsedCategories.push(category);
            });
            parsedCategories.sort(compareChilds);
            parsedCategories = addKeysToCategories(parsedCategories)
        
            setCategoriesMap(parsedCategories);
        }
        
    }, [categoriesOfItem, categoryList]);
    
    return (
        <>
            {categoriesMap != null && (  
                <Tree   treeData={categoriesMap} 
                defaultExpandAll
                className="bg-light-grey p-2"/> 
            )}   
        </>    
    );

}

export default ItemCategoriesTree;