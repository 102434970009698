import React from "react";

export function getStatusColor(status) {
    switch(status) {
        case true: //works
            return "success";

        case false: //broken
            return "danger";

        default:
            return "";
    }
}

const TransferRequestStatus = ({status}) => {

    var serviceabilityStyle = "col-12 rounded-bottom-4 mx-0 p-2 ";
    serviceabilityStyle += `bg-${getStatusColor(status)}-subtle`;

    return (
        <div className={serviceabilityStyle}></div>
    );
}

export default TransferRequestStatus;