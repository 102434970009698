import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { Statistic } from 'antd';
import MyItemsAxios from '../backendTools/MyItemsAxios';
import { useTranslation } from 'react-i18next';

const statsRequestURL = "/organization/statistic";
const statsPattern = [
  { title: 'item.total',          value: 6, color: 'optional', link:'/page/1' },
  { title: 'item.works',          value: 3, color: 'success', link: '/page/1?condition=1'},
  { title: 'item.repair.needed',  value: 2, color: 'warning', link: '/page/1?condition=2'},
  { title: 'item.broken',         value: 1, color: 'danger', link:'/page/1?condition=3'}
];

function getTextColorStyle(color) {
  return `var(--bs-${color}-text-emphasis) !important`;
}

const Statistics = () => {

  const [statisticContent, setStatisticContent] = useState([]);
  const { t } = useTranslation();

    function fillStatistic(statisticData) {
        var Stats = statsPattern;
        const parsedStats = Object.values(statisticData);
        parsedStats.forEach((element, index) => {
          if(index < Stats.length) {
            Stats[index].value = element;
          }
        });
        setStatisticContent(Stats);
    }

    useEffect(()=>{

      async function getStatistics() {
        try {
            const response = await MyItemsAxios.get(statsRequestURL);
            if(response.status === 200) {
                return response.data;
            }
        } catch (err) {
            console.error('Error submitting form:', err);
        }
      }

      getStatistics()
      .then(fillStatistic);

    }, []);


  return (
    <Row>
      {statisticContent.map((stat, index) => (
        <Col key={index} xs={6} sm={6} md={index < 4 ? 3 : 6} className="mb-4">
          <Card className="rounded-4 text-center border-0">
            <a href={stat.link} className={'link-body-emphasis link-offset-2 link-underline-opacity-0'}>
              <Card.Body className={'rounded-4 bg-' + stat.color + '-subtle border border-' + stat.color + '-subtle'}>
                <Statistic
                  title={t(stat.title)}
                  value={stat.value}
                  valueStyle={{ fontSize: '2em', color: getTextColorStyle(stat.color) }}
                  className={'text-' + stat.color + '-emphasis'}
                />
              </Card.Body>
            </a>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default Statistics;
