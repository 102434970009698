import React, { useEffect, useState } from "react";
import axios from "axios";
import backendHost from "../backendTools/backendHost";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const minPasswordLength = 5;
const allowedUsernameChars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

const RegistrationUserComplete = ({onConfirm}) => {
    const query = new URLSearchParams(useLocation().search);
    const inviteCode = query.get('activation-code');
    const email = query.get('destination-email');
    const organization = query.get('organization-name');
    const [userName, setUserName] = useState(query.get('user-name'));
    
    const [isPasswordDoubled, setPasswordDoubled] = useState(true);
    const [isPasswordShort, setPasswordShort] = useState(false);
    const [isUsernameInvalid, setUsernameInvalid] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const navigate = useNavigate();
    const { t } = useTranslation();

    async function handleRegistration(event) {
        event.preventDefault();
        const pass = document.getElementById("password1").value;
    
        const userNick = userName.trim();
        const registrationForm = {
            username: userNick,
            password: pass,
            activationCode: inviteCode
        };
        await axios.post(backendHost + "/user/member-registration", registrationForm)
            .catch(function (error) {
                if (error.response) {
                    setAlertMessage(error.response.data);
                }
            })
            .then((registration)=> {
                if(registration.status === 200) {
                    onConfirm(registration.data);
                    navigate("/");
                }
            });
        
    }

    function validatePasses() {
        const pass1 = document.getElementById("password1").value;
        const pass2 = document.getElementById("password2").value;
        if(pass2) {
            if(pass1 === pass2) {
                setPasswordDoubled(true);
            } else {
                setPasswordDoubled(false);
            }
        }
        if(pass1.length < minPasswordLength) {
            setPasswordShort(true);
        } else {
            setPasswordShort(false);
        }
    }

    function checkUsernameSymbols(event) {
        const regex = new RegExp(`^[${allowedUsernameChars}]+$`);
        setUserName(event.currentTarget.value);
        if(event.currentTarget.value !== '') {
            if(regex.test(event.currentTarget.value)) {
                setUsernameInvalid(false);
            } else {
                setUsernameInvalid(true);
            }
        } else {
            setUsernameInvalid(false);
        }
        }
        
    function backToMain() {
        navigate("/");
    }

    return (
        <div className="container py-3 py-md-5 py-lg-5" style={{'maxWidth': '700px'}}>
            <div className="row p-1 p-lg-4 justify-content-center">
                <div className="col-12 col-md-8 col-lg-8 p-3 p-md-4 p-lg-4 bg-light rounded-4">
                    <div>
                        {alertMessage && <div className="alert alert-warning alert-dismissible fade show" role="alert">
                                    {alertMessage}
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" onClick={()=>{setAlertMessage(null);}} aria-label={t('action.close')}></button>
                                </div>}
                        <form className="p-2" onSubmit={handleRegistration}> 
                            <div className="p-2">
                                <label  title={t('label.email')}
                                        htmlFor="mail"> {email} </label>
                            </div>
                            <div className="p-2">
                                <label  title={t('label.organization.name')}
                                        htmlFor="organization"> {organization} </label>
                            </div>
                            <div className="p-2">
                                <input  type="text" 
                                        id="username"
                                        className="form-control"
                                        value={userName}
                                        title={t('label.username')} 
                                        onChange={checkUsernameSymbols}/>
                            </div>
                            <div className="p-2">
                                <input  type="password" 
                                        id="password1"
                                        className="form-control"
                                        placeholder={t('label.password')} 
                                        title={t('label.password')} 
                                        onBlur={validatePasses}
                                        required/>
                                {isPasswordShort && <p style={{ color: 'red' }}>{t('password.is.too.short')}</p>}
                            </div>
                            <div className="p-2">
                                <input  type="password" 
                                        id="password2"
                                        className="form-control"
                                        placeholder={t('repeat.password')} 
                                        title={t('repeat.password')} 
                                        onBlur={validatePasses}
                                        required/>
                                {!isPasswordDoubled && <p style={{ color: 'red' }}>{t('enter.the.same.password')}</p>}
                            </div>
                            { isUsernameInvalid && <p style={{ color: 'red' }}>{t('use.just.letters')}</p>}
                            <div className="p-2">
                                <button type="submit"  className="form-control btn btn-outline-success"> {t('action.confirm')} </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegistrationUserComplete;