import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

function ItemLocation ({location, locationsList, isEditing, onChange}) {

  const [locationObject, setLocation] = useState(null);
  const [nullLocation, setNull] = useState(true);
  const { t } = useTranslation();

  useEffect(()=>{
    if(locationsList !== undefined && locationsList !== null) {
        const itemLocation = locationsList.find(loca => loca.id == location);
        if(itemLocation !== undefined) {
            setLocation(itemLocation);  //location === null
            setNull(false);
        }            
    }
      
  }, [location, locationsList]);

  function handleLocationChange(event) {
    if(onChange) {
        onChange(parseInt(event.target.value));
    } 
  }

  if(locationObject === undefined) {
      //handle data fault
      return (<>No location data...</>);
  }

  return (
    <>
      {!isEditing ? <> 
        { !nullLocation && (
            <div className="col-auto mini-box m-2">
                {(locationObject.googleMapsUrl != '' && isEditing != null) ? 
                    <a  href={locationObject.googleMapsUrl} target="_blank"
                        className="link-body-emphasis link-offset-2 link-underline-opacity-50 link-underline-opacity-75-hover">
                        <i className="bi bi-geo-alt"> </i>
                        <span>{locationObject.name}</span> 
                    </a>
                    :
                    <>
                    <i className="bi bi-geo-alt"> </i>
                    {locationObject.name} 
                    </>
                }
            </div>
      )}</> 
      : 
      <div className="col-auto input-group mini-box m-2 pe-3">
        <i className="bi bi-geo-alt h5 p-1"> </i>
        <select className="form-select"
            name="location" onChange={handleLocationChange}
            defaultValue={location}>
            <option value="" ></option>
            {locationsList.map((loca, index) => (
                <option key={index} value={loca.id}>{loca.name}</option>
            ))}
        </select>
      </div>
      }
    </> 
  );
}

export default ItemLocation;