import { getUserRoles } from "./UserStorage";
import organizationRoles from "../components/roles/OrganizationRoles";
import systemRoles from "../components/roles/SystemRoles";

export function isUserInOrganization() {
    const userRoles = getUserRoles();
    return userRoles.includes(organizationRoles.MEMBER) || userRoles.includes(organizationRoles.MANAGER) || userRoles.includes(organizationRoles.OWNER);
}

export function isUserManagerOrOwner() {
    const userRoles = getUserRoles();
    return userRoles.includes(organizationRoles.MANAGER) || userRoles.includes(organizationRoles.OWNER);
}

export function isUserOwner() {
    const userRoles = getUserRoles();
    return userRoles.includes(organizationRoles.OWNER);
}

export function isUserAdmin() {
    const userRoles = getUserRoles();
    return userRoles.includes(systemRoles.ADMIN) || userRoles.includes(systemRoles.TEMPADMIN);
}
