import React, { createContext, useEffect, useState} from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import { useCookies } from 'react-cookie';

import NavBar from './NavBar';

import ItemReceiver from './items/ItemReceiver';
import LoginPage from './user/LoginPage';
import RegistrationEntry from './user/RegistrationEntry';
import CheckMail from './user/CheckMail';
import Landing from './user/Landing';
import RegistrationCheck from './user/RegistrationCheck';
import UserList from './adminPages/UserList';
import RestorePassword from './user/RestorePassword';
import Configuration from './Configuration';
import MainPage from './MainPage';
import ListItems from './items/ListItems';
import HelpPage from './user/HelpPage';
import InviteUser from './adminPages/InviteUser';
import RegistrationBossComplete from './organization/RegistrationBossComplete';
import RegistrationUserComplete from './organization/RegistrationUserComplete';
import ImportExport from './importExport/ImportExport';
import ItemTransferRequestsList from './organization/ItemTransferRequestsList';

import { clearUserData, getUserName, setUserData } from './user/UserStorage';
import { clearAccessToken, clearRefreshToken, getRefreshToken, setAccessToken, setRefreshToken } from './user/TokensStorage';
import { isUserAdmin, isUserInOrganization } from './user/UserRoleChecks';
import ItemTransferRequestPage from './organization/ItemTransferRequestPage';
import { getLocations, getOrganizationLocations } from './LocationsList';
import { getCategories, getOrganizationCategories } from './categoryTools/CategoriesList';
import ItemConfigurator from './items/ItemConfigurator';

export const ApplicationContext = createContext();

function App() {

    const [isUserLogged, setUserLogged] = useState(false);
    const [cookies, setCookie] = useCookies(['user', 'email']);
    const [userCategories, setUserCategories] = useState([]);
    const [userLocations, setUserLocations] = useState([]);

    useEffect( () => {
        async function getLocationsAndCategories() {
            if(isUserInOrganization()) {
                const [userCategories, userLocations] = await Promise.all([getOrganizationCategories(), getOrganizationLocations()]);
                setUserCategories(userCategories);
                setUserLocations(userLocations);
            } else {
                const [userCategories, userLocations] = await Promise.all([getCategories(), getLocations()]);
                setUserCategories(userCategories);
                setUserLocations(userLocations);
            }
        }

        if(getRefreshToken()) {
            setUserLogged(true);
            getLocationsAndCategories();
        }
        
    }, []);

    function onLogin(tokens) {
        setUserLogged(true);
        setRefreshToken(tokens.refreshToken);
        setAccessToken(tokens.accessToken);   
        setUserData(tokens.user);     
    }

    function onLogout() {
        setUserLogged(false);
        clearRefreshToken();
        clearAccessToken();
        clearUserData();
    }

    function onRegister(user, email) {
        const expires = new Date();
        expires.setMonth(expires.getMonth() + 1); // Срок действия куки - 1 месяц
        setCookie('user',  user,  { path: '/', expires });
        setCookie('email', email, { path: '/', expires });
    }

    function AdminRoute ({children}) {
        return isUserAdmin() ? children : (<Navigate to="/" replace/>);
    }

    function OrganizationRoute ({children}) {
        return isUserInOrganization() ? children : (<Navigate to="/" replace/>);
    }

    function LoggedInRoute ({children}) {
        return getUserName() !==null ? children : (<Navigate to="/" replace/>);
    }

    return (
        <ApplicationContext.Provider value={{ userCategories, userLocations}}>
            <Router>
                <NavBar    
                    isLogged={isUserLogged} 
                    onLogout={onLogout} />
                <Routes>
                    <Route path="/"                             element={isUserLogged ? <MainPage/> : <Landing      />} />
                    <Route path="/login"                        element={<LoginPage onLogin={onLogin}               />} />
                    <Route path='/registration'                 element={<RegistrationEntry onRegister={onRegister} />} />
                    <Route path='/restore-password'             element={<RestorePassword onLogin={onLogin}         />} />
                    <Route path="/checkMail"                    element={<CheckMail username={cookies.user} email={cookies.email}/>} />
                    <Route path="/confirmRegistration"          element={<RegistrationCheck     onConfirm={onLogin} />} />
                    <Route path="/information"                  element={<HelpPage                                  />} />
                    <Route path="/organization-registration"        element={<RegistrationBossComplete  onConfirm={onLogin} />} />
                    <Route path="/organization/user/registration"   element={<RegistrationUserComplete  onConfirm={onLogin} />} />

                    <Route path="/page/:pageNumber"             element={<LoggedInRoute><ListItems/></LoggedInRoute>   } />
                    <Route path="/item/:id"                     element={<LoggedInRoute><ItemReceiver/></LoggedInRoute> }/>
                    <Route path="/item/:id/:tab"                element={<LoggedInRoute><ItemReceiver/></LoggedInRoute> } />
                    <Route path="/item/:id/:tab/:requestId"     element={<LoggedInRoute><ItemReceiver/></LoggedInRoute> } />
                    <Route path="/item/:id/edit"                element={<LoggedInRoute><ItemConfigurator/></LoggedInRoute> } />
                    <Route path="/configuration/:mode/:type"    element={<LoggedInRoute><Configuration/></LoggedInRoute>    } />

                    <Route path="/organization/itemTransferRequestList/:pageMode/:pageNumber" 
                            element={<OrganizationRoute><ItemTransferRequestsList/></OrganizationRoute>                     } />
                    <Route path="/organization/itemTransferRequest/:id" 
                            element={<OrganizationRoute><ItemTransferRequestPage/></OrganizationRoute>                      } />
                    <Route path="/organization/import-export"   
                            element={<OrganizationRoute><ImportExport/></OrganizationRoute>                                 } />

                    <Route path="/admin"         element={<AdminRoute><UserList/></AdminRoute>       } />
                    <Route path="/admin/invite"  element={<AdminRoute><InviteUser/></AdminRoute>     } />
                    
                    <Route path='*' element={<Navigate to="/" replace                              />} />
                </Routes>
            </Router>
        </ApplicationContext.Provider>
    );
}

export default App;
