import React, { useContext, useEffect, useState } from "react";
import RenderListItems from "../RenderListItems";
import RenderItemTransferRequest from "../components/RenderItemTransferRequest";
import ItemNoteManager from "../items/itemTools/ItemNoteManager";
import { getItemTransferRequest, updateItemTransferRequest } from "../backendTools/ItemTransferRequestsService";
import { getOrganizationItem } from "../items/ItemReceiver";
import { useParams } from "react-router-dom";
import { ApplicationContext } from "../App";
import ModalConfirm from "../components/ModalConfirm";

const ItemTransferRequestPage = ({}) => {

    const {userCategories, userLocations} = useContext(ApplicationContext);

    const { id } = useParams();
    const itemTransferRequestId = id;
    const [item, setItem] = useState({});
    const [itemTransferRequestContent, setItemTransferRequestContent] = useState(null);
    const [itemPage, setItemPage] = useState(); 

    const [modalConfirmOpen, setModalConfirmOpen] = useState(false);
    const [modalConfirmMessage, setModalConfirmMessage] = useState('');
    const [modalConfirmData, setModalConfirmData] = useState(null);

    useEffect(() => {
        async function receiveItemTransferRequest() {
            const localItemTransferRequest =  await getItemTransferRequest(itemTransferRequestId);
            const localItem = await getOrganizationItem(localItemTransferRequest.itemId);
            setItemTransferRequestContent(localItemTransferRequest);
            setItem(localItem);
            setItemPage({content: [localItem]});
        }
        
        //get request, get item, get categories & locations
        receiveItemTransferRequest();
    }, [itemTransferRequestId]);

    async function handleItemTransferDecision (itemTransferRequestId, itemTransferRequestAction) {
        setModalConfirmOpen(true);
        setModalConfirmMessage(`${itemTransferRequestAction ? 'Approve' : 'Decline'} request from ${itemTransferRequestContent.receiverName}?`);
        setModalConfirmData({itemTransferRequestId: itemTransferRequestId, action: itemTransferRequestAction});    
    };

    function handleModalDecline() {
        setModalConfirmOpen(false);
        setModalConfirmData({itemTransferRequestId: 0, action: null}); 
    }
    
    async function handleModalAccept() {
        setModalConfirmOpen(false);
        try {
            await updateItemTransferRequest(modalConfirmData.itemTransferRequestId, modalConfirmData.action);
        } catch (error) {
            console.error('Error performing action:', error);
        }
        window.location.reload();
    }

    return (
    <div className="container align-content-center mt-5 p-3" style={{'maxWidth': '700px'}}>
        {itemTransferRequestContent && userCategories.length > 0 && userLocations.length > 0 && item &&
            <div className='row'>
                <RenderItemTransferRequest 
                    itemTransferRequest={itemTransferRequestContent} 
                    onAction={handleItemTransferDecision} />
                <RenderListItems 
                    itemsPagingData={itemPage} 
                    categoriesList={userCategories} 
                    locationsList={userLocations} />
                <ItemNoteManager itemId={itemTransferRequestContent.itemId} noteList={item.notes}/>
            </div>
        }
        <ModalConfirm isOpen={modalConfirmOpen} 
                        message={modalConfirmMessage}
                        onConfirm={handleModalAccept}
                        onDecline={handleModalDecline}/>         
    </div>
    );
}

export default ItemTransferRequestPage;
