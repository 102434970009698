import React, {useState, useEffect} from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Modal } from "react-bootstrap";

const defaultMaxHeight = 200;
const mobileMaxHeight = 450;
const bootstrapMinWidthForMD = 576;

const s3bucketUrl = 'https://my-items-net.s3.amazonaws.com/';

function ItemImage ({photo}) {

    const [displayImage, setDisplayImage] = useState(null);
    const [isFullScreen, setFullScreen] = useState(false);
    const [maxHeight, setMaxHeight] = useState(defaultMaxHeight);
    
    useEffect(() => {

        function isServerImageEmpty () {
            return (photo == null || (photo.photoBase64 == null && photo.fileName == null));
        }

        function getBase64Url() {
            //set base64 data string with foto as constant
            const base64String = photo.photoBase64;
            // Create a data URL
            return (`data:image/;base64,${base64String}`); // Adjust the mime type if necessary
        }

        function showImage() {
            var dataUrl;
            if(isServerImageEmpty()) {
                dataUrl = '/NoImage.png';
            } else if(photo.fileName != null) {
                dataUrl = s3bucketUrl + photo.fileName;
            } else {
                dataUrl = getBase64Url();
            }
            setDisplayImage(dataUrl);
        };

        showImage();
        if(window.innerWidth < bootstrapMinWidthForMD) {
            setMaxHeight(mobileMaxHeight);
        }
    }, [photo]);


    function showFullScreen() {
        setFullScreen(true);
    }

    function hideFullScreen() {
        setFullScreen(false);
    }

    return (
        <>
            <img className="img-fluid p-2 rounded-4" alt='' src={displayImage} onClick={showFullScreen} style={{ cursor: 'pointer', maxHeight : maxHeight }}/>
            <Modal  show={isFullScreen} 
                    onHide={hideFullScreen}
                    centered size="xl"
                    className="d-flex justify-content-center"
                    contentClassName="rounded-4 d-flex justify-content-center">
                <Modal.Body>
                    <img src={displayImage} className="image-limited"></img>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ItemImage;