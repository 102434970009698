import React, { useState, useEffect, useContext } from 'react';
import MyItemsAxios from '../backendTools/MyItemsAxios';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ItemName from './itemTools/ItemName';
import ItemServicability from './itemTools/ItemServicability';
import ItemLocation from './itemTools/ItemLocation';
import ItemDeliveryStatus from './itemTools/ItemDeliveryStatus';
import ItemDescription from './itemTools/ItemDescription';
import {transformNullsToZeros} from '../categoryTools/CategoryTree';
import BackButton from '../components/BackButton';
import { ApplicationContext } from '../App';
import ItemCategoriesSelect from '../components/ItemCategoriesSelect';
import AnyPageIconTabs from '../components/AnyPageIconTabs';
import ItemImageUpdater from './itemTools/ItemImageUpdater';
import AnyPageIconTextTabs from '../components/AnyPageIconTextTabs';

const requestItemUrl = "/organization/item";

const defaultItem = {
  id : 0,
  name : '',
  location : '',
  description : '',
  stateOfServiceability : 1,
  categories: [],
  isDelivered: null,
  photo: null,
  notes: [],
  userId: null,
  user : null,
}

const itemConfiguratorTabs = {
    COMMON : {
      path : 'common',
      iconBi : 'bi-info-circle',
      translateTag : 'tab.general'
    },
    CATEGORIES : {
      path : 'categories',
      iconBi : 'bi-diagram-3',
      translateTag : 'config.categories'
    }, 
    IMAGE : {
      path : 'image',
      iconBi : 'bi-card-image',
      translateTag : 'tab.image'
    },
};

async function getOrganizationItem(id) {
  try {
    const response = await MyItemsAxios.get( requestItemUrl + '/' + id);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

async function getItem(id) {
  try {
    const response = await MyItemsAxios.get(requestItemUrl + '/' + id);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

function ItemConfigurator() {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [item, setItem] = useState(null);
  const [categoriesList, setCategoriesList] = useState(null);
  const [locations, setLocations] = useState(null);
  const [alertVisible, setAlertVisible] = useState(false);
  const [itemTab, setItemTab] = useState(itemConfiguratorTabs.COMMON.path);

  const {userCategories, userLocations} = useContext(ApplicationContext);

  useEffect(() => {

    // Request item data after page is loaded
    async function loadItem() {
      if(id === '0') {
        setItem(defaultItem);
      } else {
        const localItem = await getOrganizationItem(id);
        setItem(localItem);
      }
    }
    
    setCategoriesList(transformNullsToZeros(userCategories));
    setLocations(userLocations);
    loadItem();
  }, [id, userLocations, userCategories]); // Empty array means this effect runs only once after the component mounts

  async function sendItem() {
    if(item.id === 0) {
      try {
        const response = await MyItemsAxios.post(requestItemUrl, item);
        setItem(response.data);
        navigate(`/item/${response.data.id}`, {
          state: { from: `/`}, 
        });
      } catch (error) {
        console.error('Error creating item:', error);
      }
      
    } else {
      try {
        await MyItemsAxios.put(requestItemUrl, item);
        setAlertVisible(true);
        setTimeout(() => setAlertVisible(false), 3000);
      } catch (error) {
        console.error('Error updating item:', error);
      }
    }
    
  }

  function onItemNameChange(newName) {
    setItem(prevState => ({
      ...prevState, name: newName
    }));
  }

  function onItemServisabilityChange(newCondition) {
    setItem(prevState => ({
      ...prevState, stateOfServiceability: newCondition
    }));
  }

  function onItemDescriptionChange(newDescription) {
    setItem(prevState => ({
      ...prevState, description: newDescription
    }));
  }

  function onItemLocationChange(newLocation) {
    setItem(prevState => ({
      ...prevState, location: newLocation
    }));
  }

  async function handleAddCategory(categoryIdArray) {
    try {
      const categoryUpdateURl = requestItemUrl + "/" + id + "?category=" + categoryIdArray;
      await MyItemsAxios.put(categoryUpdateURl, null);
      setItem(prevState => ({
        ...prevState, categories: categoryIdArray
      })); 
      setAlertVisible(true);
      setTimeout(() => setAlertVisible(false), 4000);      
    } catch {
      console.error("Error adding category");
    }
  }

  if(userCategories === undefined) {
    //placeholder while receiving data, normally invisible 
    return (<div></div>);
  }

  return (
      <div className="container py-2 px-4" style={{'maxWidth': '700px'}}>
        <div className='pb-2'>
            <BackButton />
        </div>
        {item ? (
          <>
          <div className="row border rounded-4 bg-light-grey p-2 pt-2">
            <div className='d-flex justify-content-between'>
              <div className='col-12'>
                <AnyPageIconTextTabs activeTab={itemTab} onTabChange={setItemTab} tabsSet={Object.values(itemConfiguratorTabs)}/>
              </div>
            </div>
            <div className='col-12 p-2'>
              {itemTab === itemConfiguratorTabs.COMMON.path && (
              <>
              <div className='d-flex flex-row-reverse me-1'>
                <div className='btn btn-outline-secondary m-2 me-2' onClick={sendItem}>
                  <i className="bi bi-floppy h6"></i> 
                </div>
              </div>
                
                <div className='row p-1'> 
                  <div className='col-12'>
                    <ItemName name={item.name} isEditing={true} updateName={onItemNameChange}/>
                    <ItemServicability  serviceable={item.stateOfServiceability} isEditing={true}
                                    onChange={onItemServisabilityChange}/>
                    <ItemLocation location={item.location} locationsList={locations}
                                  isEditing={true} onChange={onItemLocationChange}/>
                    <ItemDeliveryStatus status={item.delivered}/>
                    <ItemDescription  description={item.description} isEditing={true}
                                      onChange={onItemDescriptionChange}/>
                  </div>
                </div>
                
              </>
              )}
              {itemTab === itemConfiguratorTabs.CATEGORIES.path && 
              <ItemCategoriesSelect
                categoryList={categoriesList} 
                categoriesOfItem={item.categories}
                handleAddCategory={handleAddCategory}/>
              }
              {itemTab === itemConfiguratorTabs.IMAGE.path && 
                <ItemImageUpdater photo={item.photo} itemId={item.id}/>
              }
            </div>
          </div>
          </>
        ) : (
          <div className='row p-5 justify-content-center'>
            <div className="spinner-border" role="status">
              <span className="visually-hidden">{t('label.loading')}</span>
            </div>
          </div>
        )}
         
        <div  className={`alert alert-secondary floating-alert mt-3 ${alertVisible ? "show" : "fade"}`}
              role="alert">
            {t('alert.updated.data')}
        </div>
      </div>
  );
}

export default ItemConfigurator;
export {getItem, getOrganizationItem, requestItemUrl};
