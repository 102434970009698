const tokenNames = {
    ACCESS : 'accessToken',
    REFRESH: 'refreshToken'
};

export function setAccessToken(accessToken) {
    localStorage.setItem(tokenNames.ACCESS, accessToken);
}

export function getAccessToken() {
    return localStorage.getItem(tokenNames.ACCESS);
}

export function clearAccessToken() {
    localStorage.removeItem(tokenNames.ACCESS);
}

export function setRefreshToken(refreshToken) {
    localStorage.setItem(tokenNames.REFRESH, refreshToken);
}

export function getRefreshToken() {
    return localStorage.getItem(tokenNames.REFRESH);
}

export function clearRefreshToken() {
    localStorage.removeItem(tokenNames.REFRESH);
}