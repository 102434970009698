import React, { useState, useEffect, useContext } from 'react';
import MyItemsAxios from '../backendTools/MyItemsAxios';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ItemName from './itemTools/ItemName';
import ItemImage from './itemTools/ItemImage';
import ItemServicability from './itemTools/ItemServicability';
import ItemLocation from './itemTools/ItemLocation';
import ItemDeliveryStatus from './itemTools/ItemDeliveryStatus';
import ItemDescription from './itemTools/ItemDescription';
import ItemNoteManager from './itemTools/ItemNoteManager';
import {transformNullsToZeros} from '../categoryTools/CategoryTree';
import BackButton from '../components/BackButton';
import { ApplicationContext } from '../App';
import ItemAssignee from './itemTools/ItemAssignee';
import ItemQrCode from '../components/ItemQrCode';
import ItemTransferRequestCreator from '../components/ItemTransferRequestCreator';
import { getUsers } from '../organization/MemberList';
import organizationRoles from '../components/roles/OrganizationRoles';
import ItemCategoriesTree from '../items/itemTools/ItemCategoriesTree'
import ItemTransferRequests from './itemTools/ItemTransferRequests';
import { getServiceabiltyColor } from './itemTools/ItemCardServisability';
import ModalConfirm from '../components/ModalConfirm';
import AnyPageIconTextTabs from '../components/AnyPageIconTextTabs';

const requestItemUrl = "/organization/item";

const itemPageTabsSet = {
  COMMON : {
    path : 'common',
    iconBi : 'bi-info-circle',
    translateTag : 'tab.general'
  },
  CATEGORIES : {
    path : 'categories',
    iconBi : 'bi-diagram-3',
    translateTag : 'config.categories'
  }, 
  QR_CODE : {
    path : 'code',
    iconBi : 'bi-qr-code',
    translateTag : 'item.QR-code'
  },
  TRANSFER : {
    path : 'transfer',
    iconBi : 'bi-arrow-down-up',
    translateTag : 'tab.item.transfer'
  },
  TRANSFER_REQUESTS : {
    path : 'requests',
    iconBi : 'bi-card-checklist',
    translateTag : 'tab.item.requests'
  },
  JOURNAL : {
    path : 'journal',
    iconBi : 'bi-journal-text',
    translateTag : 'tab.item.log'
  }
};

async function getOrganizationItem(id) {
  try {
    const response = await MyItemsAxios.get( requestItemUrl + '/' + id);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

async function getItem(id) {
  try {
    const response = await MyItemsAxios.get(requestItemUrl + '/' + id);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

function ItemReceiver() {
  const { id, tab, requestId } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const [item, setItem] = useState(null);
  const [categoriesList, setCategoriesList] = useState(null);
  const [managerList, setManagerList] = useState([]);
  const [singleRequest, setSingleRequest] = useState(null);

  const defaultTab = Object.values(itemPageTabsSet).find(itemTab => itemTab.path === tab) ? tab : itemPageTabsSet.COMMON.path;
  const [itemTab, setItemTab] = useState(defaultTab);
  const [deleteModalState, setDeleteModalState] = useState(false);

  const { t } = useTranslation();

  const {userCategories, userLocations} = useContext(ApplicationContext);

  useEffect(() => {

    // Request item data after page is loaded
    async function loadItem() {
      const localItem = await getOrganizationItem(id);
      setItem(localItem);
    }

    async function loadManagers() {
      const usersList = await getUsers();
      filterManagers(usersList);
    }
    
    setCategoriesList(transformNullsToZeros(userCategories));
    loadItem();
    loadManagers();
  }, [id, userCategories]);

  useEffect(()=>{
    if(requestId !== undefined) {
      setSingleRequest(requestId);
    } else {
      setSingleRequest(null);
    }
  }, [requestId]);

  useEffect(() => {
    if(tab) {
      setItemTab(tab);
    }
    
  }, [tab]);

  function filterManagers (userList) {
    let managerArray = [];
    // sort user list based on role
    userList.forEach(user => {
        if (user.roles.includes(organizationRoles.MANAGER)) {
            managerArray.push(user);  
        } 
    });
    setManagerList(managerArray);
  }
  function updateTab(newTab) {
    setSingleRequest(null);
    setItemTab(newTab);
    navigate(`/item/${id}/${newTab}`, {replace: true});
  }
  

  function goItemConfiguration() {
    navigate(`/item/${item.id}/edit`, { state: { from: location.pathname } });
  }

  async function deleteItem() {
    try {
      await MyItemsAxios.delete(requestItemUrl + '/' + id);
        if (location.state?.from) {
          navigate(-1); // Переход на предыдущую страницу
        } else {
          navigate('/page/1'); // Если нет предыдущей страницы
        }
    } catch(error) {
      console.error("Delete item not available! Error: ", error);
      setDeleteModalState(false);
    } 
  }

  if(userCategories === undefined) {
    //placeholder while receiving data, normally invisible 
    return (<div></div>);
  }

  return (
      <div className="container py-2 px-4" style={{'maxWidth': '700px'}}>
        <div className='pb-2'>
            <BackButton />
        </div>
        {item ? (
          <>
          <div className={`row border rounded-4 bg-light-grey p-2 pt-2 border-${getServiceabiltyColor(item.stateOfServiceability)}-subtle` }>
            <div className='col-12 p-3 m-0'>
              <div className='row justify-content-between'>
                <div className='col'>
                  <ItemName name={item.name} isEditing={false}/>
                    <ItemServicability  serviceable={item.stateOfServiceability} isEditing={false}/>
                </div>
                <div className='col-auto'>
                    <i className="btn btn-outline-secondary bi bi-pencil mx-1" onClick={goItemConfiguration} ></i> 
                    <i className='btn btn-outline-danger bi bi-trash mx-1' onClick={()=>{setDeleteModalState(true);}}> </i> 
                </div>
              </div> 
            </div>
            <AnyPageIconTextTabs  activeTab={itemTab} onTabChange={updateTab} tabsSet={Object.values(itemPageTabsSet)} />
            <div className='col-12 mt-2'>
              {itemTab === itemPageTabsSet.COMMON.path && (
              <div className='row p-2'>
                <div className="col-12 col-sm-4 col-lg-3 mt-0  mt-sm-2 p-0">
                  <ItemImage photo={item.photo} itemId={item.id}/>
                </div>

                <div className="col-12 col-sm-8 align-top mt-3 h-100">
                    <ItemAssignee itemUser={item.user} itemUserId={item.userId} />
                    <ItemLocation location={item.location} locationsList={userLocations}
                                  isEditing={false}/>
                    <ItemDeliveryStatus status={item.delivered}/>
                    <ItemDescription  description={item.description} isEditing={false}/>
                </div>
              </div>
              )}
              {itemTab === itemPageTabsSet.CATEGORIES.path && 
              <ItemCategoriesTree
                categoryList={categoriesList} 
                categoriesOfItem={item.categories}
                />
              }
              {itemTab === itemPageTabsSet.QR_CODE.path && 
              <ItemQrCode itemID={item.id} itemName={item.name}/>
              }
              {itemTab === itemPageTabsSet.TRANSFER.path &&
                <ItemTransferRequestCreator itemId={item.id} managerList={managerList} />
              }
              {itemTab === itemPageTabsSet.TRANSFER_REQUESTS.path && 
                <ItemTransferRequests itemId={item.id} requestId={singleRequest} />
              }
            </div>
          </div>
          {itemTab === itemPageTabsSet.COMMON.path && 
            <ItemNoteManager itemId={item.id} noteList={item.notes}/>
          }
          
          </>
        ) : (
          <div className='row p-5 justify-content-center'>
            <div className="spinner-border" role="status">
              <span className="visually-hidden">{t('label.loading')}</span>
            </div>
          </div>
        )}
        <ModalConfirm message={t("item.delete")}
                      isOpen={deleteModalState}
                      onConfirm={deleteItem}
                      onDecline={()=>{setDeleteModalState(false);}}/> 
      </div>
  );
}

export default ItemReceiver;
export {getItem, getOrganizationItem, requestItemUrl};
