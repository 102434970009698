import React from "react";
import { useTranslation } from 'react-i18next';

function ItemDescription ({description, isEditing, onChange}) {
    const { t } = useTranslation();

    function handleDescriptionChange(event) {
        if(onChange) {
            onChange(event.target.value);
        } 
    }
    
    return (
        <>
        { ((description != null && description !== "") || isEditing) && (
            <div className="col-auto m-2">  
                
                {isEditing ? 
                    <div className="input-group">
                        <i className="bi bi-info-square h5 p-1 pt-2"> </i>
                        <textarea
                        className="form-control comment-input mt-2"
                        value={description}
                        onChange={handleDescriptionChange}
                        rows={3}>
                        </textarea> 
                    </div>
                    : 
                    <div><i className="bi bi-info-square h6"> </i> {description} </div>
            }
            </div>
        )}
        </>
    );
}

export default ItemDescription;