import React, { useContext, useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { Tree } from "antd";
import 'antd/dist/reset.css';

import { addDays } from 'date-fns';

import { useTranslation } from 'react-i18next';
import transformTree, {getCategoryIdByKey, getCategoryKeyById, isCategoryEndByKey} from '../categoryTools/CategoryTree';
import CalendarInput from "./CalendarInput";
import DateToString from "./DateToString";
import { ApplicationContext } from "../App";
import { getUsers } from "../organization/MemberList";
import SearchTabs, { searchTabsSet } from "./SearchTabs";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const ModalSelector = ({isOpen, onClose}) => {
    const query = useQuery();
    const categoryIdSet = query.get('category') || '';
    const locationIdSet = query.get('location-id') || '';
    const conditionSet = query.get('condition') || '';
    const createTimeStart = query.get('create-start') || null;
    const createTimeEnd = query.get('create-end') || null;
    const updateTimeStart = query.get('update-start') || null;
    const updateTimeEnd = query.get('update-end') || null;
    const selectedUser = query.get('item-user') || '';

    const [searchFormData, setSearchFormData] = useState({condition: conditionSet, location: locationIdSet, assignee: selectedUser});

    const [activeContentTab, setActiveContentTab] = useState(searchTabsSet.COMMON.path);

    const {userLocations, userCategories} = useContext(ApplicationContext);
    const [membersList, setMembersList] = useState();
    const [categoryTree, setTree] = useState(null);
    
    const [checkedCategories, setCheckedKeys] = useState([]);
    
    const [datesCreation, setDatesCreation] = useState({
        startDate: new Date(),
        endDate: addDays(new Date(), 7),
        key: 'selection',
    });
    const [datesUpdate, setDatesUpdate] = useState({
        startDate: new Date(),
        endDate: addDays(new Date(), 7),
        key: 'selection',
    });
    const [creationSet, setCreationSet] = useState((createTimeStart!= null && createTimeEnd != null));
    const [updateSet, setUpdateSet] = useState((updateTimeStart!= null && updateTimeEnd != null));
    
    const { i18n, t } = useTranslation();
    const userLocale = i18n.language;

    const navigate = useNavigate();

    function handleClose() {
        if(onClose != null) {
            onClose();
        }
    }

    const handleFormChange = (e) => {
        setSearchFormData({ ...searchFormData, [e.target.name]: e.target.value });
      };

    const onCategoryCheck = (checkedKeysValue) => {
        setCheckedKeys(checkedKeysValue);
    };

    function onCreationDateChange(dates, setState) {
        setCreationSet(setState);
        if(setState) {
            setDatesCreation(dates);
        }
    }

    function onUpdateDateChange(dates, setState) {
        setUpdateSet(setState);
        if(setState) {
            setDatesUpdate(dates);
        }
    }

    function handleReset() {
        setCheckedKeys([]);
        setCreationSet(false);
        setUpdateSet(false);
        setSearchFormData({condition: '', location: '', assignee: ''});
    }

    function handleConfirm() {

        var requestFieldsJoiner = '';
        var categoriesRequest = '';
        var selectedCategories = [];
        if(checkedCategories.length) {
            checkedCategories.forEach(categoryKey => {
                if(isCategoryEndByKey(categoryKey)) {
                    selectedCategories.push(getCategoryIdByKey(categoryKey));
                }
                categoriesRequest = `${requestFieldsJoiner}category=${selectedCategories}`;
            });
            requestFieldsJoiner = '&';
        }

        var createdIn = '';
        if(creationSet) {
            const startDate = DateToString(datesCreation.startDate);
            const endDate   = DateToString(datesCreation.endDate);
            const formattedString = `${requestFieldsJoiner}create-start=${startDate}&create-end=${endDate}`;
            createdIn = formattedString;
            requestFieldsJoiner = '&';
        }

        var editedIn = '';
        if(updateSet) {
            const startDate = DateToString(datesUpdate.startDate);
            const endDate   = DateToString(datesUpdate.endDate);
            const formattedString = `${requestFieldsJoiner}update-start=${startDate}&update-end=${endDate}`;
            editedIn = formattedString;
            requestFieldsJoiner = '&';
        } 
        
        var servisabilityRequest = '';
        if(searchFormData.condition) {
            servisabilityRequest = `${requestFieldsJoiner}condition=${searchFormData.condition}`;
            requestFieldsJoiner = '&';
        }
            

        var locationRequest = '';
        if(searchFormData.location != '') {
            locationRequest = `${requestFieldsJoiner}location-id=${searchFormData.location}`;
            requestFieldsJoiner = '&';
        }

        var itemUserRequest = '';
        if(searchFormData.assignee) {
            itemUserRequest = `${requestFieldsJoiner}item-user=${searchFormData.assignee}`;
            requestFieldsJoiner = '&';
        }

        if(onClose != null) {
            onClose();
        }
        navigate("/page/1?" + categoriesRequest + 
                locationRequest + 
                servisabilityRequest + 
                createdIn + 
                editedIn + 
                itemUserRequest);
        window.location.reload();
    }

    useEffect(() => {

        function checkCategories() {
            if(categoryIdSet != null) {
                const categoryIds = categoryIdSet.split(',');
                var categoriesPreset = [];
                categoryIds.forEach(categoryId => {
                    if(categoryId != "")
                        categoriesPreset.push(getCategoryKeyById(categoryId));
                });
                setCheckedKeys(categoriesPreset);
            }
        }

        function checkDates() {
            if(createTimeStart != null && createTimeEnd != null) {
                const startDate = new Date(createTimeStart);
                const endDate   = new Date(createTimeEnd);
                setDatesCreation(prevState => ({...prevState, startDate, endDate}));
            }
            if(updateTimeStart != null && updateTimeEnd != null) {
                const startDate = new Date(updateTimeStart);
                const endDate   = new Date(updateTimeEnd);
                setDatesUpdate(prevState => ({...prevState, startDate, endDate}));
            }
        }
        async function receiveUsers() {
            setMembersList( await getUsers());
        }

        receiveUsers();
        setTree(transformTree(userCategories));
        checkCategories()
        checkDates();
        
    }, [userCategories]);

    return (
        <>      
            <Modal show={isOpen} onHide={handleClose} contentClassName="rounded-4">
                <Modal.Body>
                    <SearchTabs activeTab={activeContentTab} onTabChange={setActiveContentTab} />
                    <form>
                    <div className="row p-2">
                        <div className={"col-12 p-2 border border-2 rounded-4" +
                                        (checkedCategories.length === 0 ? "" : " border-primary")}
                             hidden={activeContentTab !== searchTabsSet.CATEGORIES.path}>
                            < Tree   treeData={categoryTree}
                                checkable 
                                onCheck={onCategoryCheck}
                                checkedKeys={checkedCategories}
                                className="bg-light-grey"/> 
                        </div>
    
                        <div className="col-12 p-0 ps-md-2" hidden={activeContentTab !== searchTabsSet.COMMON.path}>
                            <div className={"col-12 p-3 mb-3 mt-3 input-group mt-md-0 border border-2 rounded-4" + (searchFormData.condition == '' ? "" : " border-primary")}>
                            <i className="bi bi-wrench h4 m-2 pe-1" title={t('item.condition')}></i> 
                                <select className="form-select" aria-label={t('item.condition')} 
                                        name="condition" onChange={handleFormChange}
                                        value={searchFormData.condition}>
                                <option value=""></option>
                                <option value="1">{t('condition.works')}</option>
                                <option value="2">{t('condition.repair.needed')}</option>
                                <option value="3">{t('condition.broken')}</option>
                                </select>
                            </div>

                            <div className={"col-12 p-3 mt-3 input-group border border-2 rounded-4" + (searchFormData.location == '' ? "" : " border-primary")}>
                                <i className="bi bi-geo-alt m-2 h4 pe-1" title={t('label.location')}></i>
                                <select className="form-select" aria-label={t('item.location')} 
                                        name="location" onChange={handleFormChange}
                                        value={searchFormData.location}>
                                <option value={''}></option>
                                { userLocations.map((location, index) => (
                                    <option key={index} value={location.id}>{location.name}</option>
                                ))}
                                </select>
                            </div>

                            {membersList && 
                            <div className={"col-12 p-3 mt-3 input-group border border-2 rounded-4" + (searchFormData.assignee == '' ? "" : " border-primary")}>
                                <i className="bi bi-person m-2 h4 pe-1" title={t('label.item.user')}></i>
                                <select className="form-select" aria-label={t('item.item.user')} 
                                        name="assignee" onChange={handleFormChange}
                                        value={searchFormData.assignee}>
                                <option value={''}></option>
                                { membersList?.map((member, index) => (
                                    <option key={index} value={member.id}>{member.username}</option>
                                ))}
                                </select>
                            </div>
                            }
                            
                        </div>
                    </div>
                    <div className="row p-2" hidden={activeContentTab !== searchTabsSet.TIME_PARAMETERS.path}>
                            <div className={"col-12 p-3 mb-2 border border-2 rounded-4" + (creationSet == false ? "" : " border-primary")}>
                                <div className="h6"> {t('filter.created')} </div>
                                <CalendarInput 
                                    selectedDates={datesCreation}
                                    updateDates={onCreationDateChange}
                                    locale={userLocale}/>
                            </div>

                            <div className={"col-12 p-3 mt-2 border border-2 rounded-4" + (updateSet == false ? "" : " border-primary")}>
                                <div className="h6"> {t('filter.modified')} </div>
                                <CalendarInput 
                                    selectedDates={datesUpdate}
                                    updateDates={onUpdateDateChange}
                                    locale={userLocale}/>
                            </div>
                    </div>
                    </form>    
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Button variant="outline-secondary" onClick={handleReset}>
                        <i className="bi bi-arrow-counterclockwise h5"></i>
                    </Button>
                    <Button variant="outline-secondary" onClick={handleConfirm}>
                        <i className="bi bi-search h5"></i>
                    </Button>                        
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ModalSelector;
