import React, { useState, useEffect, useContext } from 'react';
import { Tree } from 'antd';
import { useTranslation } from 'react-i18next';

import MyItemsAxios from '../backendTools/MyItemsAxios';
import transformTree, { getCategoryIdByKey } from './CategoryTree';
import ModalConfirm from '../components/ModalConfirm';
import CategoriesCreator from '../CategoriesCreator';
import { ApplicationContext } from '../App';
import { isUserInOrganization, isUserManagerOrOwner } from '../user/UserRoleChecks';

const requestPersonalCategoriesURl = "/organization/category";
const requestOrganizationCategoriesURl = "/organization/category";
const manageOrganizationCategoriesUrl = "/organization/manager/category"
const nullCategory = {id : null, name : '', parent : 0 };

async function getCategories () {
    try {
        const respondedCategories = await MyItemsAxios.get(requestPersonalCategoriesURl);
        return respondedCategories.data;
    } catch (error) {
        console.error('Error receiving data:', error);
    }
};

async function getOrganizationCategories () {
    try {
        const respondedCategories = await MyItemsAxios.get(requestOrganizationCategoriesURl);
        return respondedCategories.data;
    } catch (error) {
        console.error('Error receiving data:', error);
    }
};

function CategoriesList () {

    const {userCategories} = useContext(ApplicationContext);

    const requestCategoriesURL = isUserInOrganization() ? (isUserManagerOrOwner() ? manageOrganizationCategoriesUrl : requestOrganizationCategoriesURl) : requestPersonalCategoriesURl;

    const [categoryTree, setTree] = useState(null); 
    const [categoriesArray, setCategoriesArray] = useState([]);

    const [isEditorOpen, setEditorOpen] = useState(false);
    const [editingCategory, setEditingCategory] = useState(nullCategory);

    const [deleteModalState, setDeleteModalState] = useState(false);
    const [deletingCategory, setDeletingCategory] = useState('');
    const [deletingMessage, setDeletingMessage] = useState('');
    const { t } = useTranslation();

    function openEditor(categoryId) {
        if(categoryId) {
            setEditingCategory(categoriesArray.find( listedCategory => listedCategory.id == categoryId));
        } else {
            setEditingCategory(nullCategory);
        }
        setEditorOpen(true);
    }

    function closeEditor(category) {
        if(category) {
            const foundIndex = categoriesArray.findIndex(cat => cat.id == category.id);
            if(foundIndex !== -1) {
                categoriesArray[foundIndex] = category;
            } else {
                categoriesArray.push(category);
            }
            
            setCategoriesArray(categoriesArray);
            setTree(transformTree(categoriesArray));
        }
        setEditorOpen(false);
    }

    function deleteCategory() {
        try {
            MyItemsAxios.delete(requestCategoriesURL + '/' + deletingCategory.id);
            const newCategoriesArray = categoriesArray.filter(category => category.id !== deletingCategory.id)
            setCategoriesArray(newCategoriesArray);
            setTree(transformTree(newCategoriesArray));
            setDeleteModalState(false);
        } catch (error) {
            console.error(error);
        }
    }

    const renderTreeNodes = (data) =>
        data.map((item) => {
          if (item.children) {
            return {
              title: (
                <span>
                    {item.title}
                    {item.children.length === 0 && <span>  
                        <i className="bi bi-trash p-1" onClick={(e)=>{e.stopPropagation(); setDeleteModalState(true); setDeletingCategory(item);}}></i>  </span>}
                    </span>
              ),
              key: item.key,
              children: renderTreeNodes(item.children),
            };
          }
    
          return {
            title: (
              <span>
                {item.title}
                <span>  <i className="bi bi-trash p-1" onClick={(e)=>{e.stopPropagation(); setDeleteModalState(true); setDeletingCategory(item);}}></i>  </span>
              </span>
            ),
            key: item.key,
          };
        });

    function onNodeClick(selectedKeys, info) {
        const selectedCategoryId = getCategoryIdByKey(selectedKeys[0]);
        openEditor(selectedCategoryId);        
    }

    useEffect(() => {

        //check categories not empty then process and allow render
        if(userCategories !== undefined && userCategories.length) {
            setCategoriesArray(userCategories);
            setTree(transformTree(userCategories));
        }
        
    }, [userCategories]);

    useEffect(() => {
        var newMessage = t('category.delete');
        newMessage += ' "' + deletingCategory.title + '"?';
        setDeletingMessage(newMessage);
    }, [deletingCategory]);

    return (
        <div className="row border rounded-4 mt-3 mx-0">
            {categoryTree && (
                    <div className='col-11 p-3'>
                        <Tree   treeData={renderTreeNodes(categoryTree)}
                                showLine
                                onSelect={onNodeClick}
                                defaultExpandAll={true}
                                className='settings-page-tree'/>
                    </div>
                    
            )}
            <div className='col-1 m-0'>
                <span className='btn btn-grey' onClick={()=>{openEditor(0);}}> <i className="bi bi-file-earmark-plus"></i></span>
            </div>
            <CategoriesCreator  isOpen={isEditorOpen}
                                handleClose={closeEditor}
                                category={editingCategory}
                                categoriesList={categoriesArray}/>
            <ModalConfirm message={deletingMessage}
                          isOpen={deleteModalState}
                          onConfirm={deleteCategory}
                          onDecline={()=>{setDeleteModalState(false);}}/>          
        </div>
    );
}

export default CategoriesList;
export {getCategories, getOrganizationCategories};
export {requestOrganizationCategoriesURl, requestPersonalCategoriesURl, manageOrganizationCategoriesUrl};
