import React, { useState, useEffect, useContext } from 'react';
import { Tree } from 'antd';
import { useTranslation } from 'react-i18next';

import MyItemsAxios from './backendTools/MyItemsAxios';
import ModalConfirm from './components/ModalConfirm';
import LocationCreator from './LocationCreator';
import { ApplicationContext } from './App';
import { isUserInOrganization, isUserManagerOrOwner } from './user/UserRoleChecks';

const requestPersonalLocationsURl = "/organization/location";
const requesOrganizationtLocationsURl = "/organization/location";
const manageOrganizationLocationsUrl = "/organization/manager/location"

const nullLocation = {id : null, name : '', address : '', googleMapsUrl : '' };

async function getLocations () {
    try {
        const respondedLocations = await MyItemsAxios.get(requestPersonalLocationsURl);
        return respondedLocations.data;
    } catch (error) {
        console.error('Error receiving data:', error);
    }
};

async function getOrganizationLocations () {
    try {
        const respondedLocations = await MyItemsAxios.get(requesOrganizationtLocationsURl);
        return respondedLocations.data;
    } catch (error) {
        console.error('Error receiving data:', error);
    }
};

function transformLocationsTree(locations) {
    const locationsTree = [];
    locations.forEach(element => {
        const locationNode = {
            id : element.id,
            key : element.id,
            title : element.name
        };
        if(element.googleMapsUrl) {
            locationNode.googleMapsUrl = element.googleMapsUrl;
        }
        locationsTree.push(locationNode);
    });
    return locationsTree;
}

function LocationsList () {
    const {userLocations} = useContext(ApplicationContext);
    const requestLocationURL = isUserInOrganization() ? (isUserManagerOrOwner() ? manageOrganizationLocationsUrl : requesOrganizationtLocationsURl) : requestPersonalLocationsURl;

    const [locationsTree, setLocationsTree] = useState(null); 
    const [locationsArray, setLocationsArray] = useState([]);

    const [isEditorOpen, setEditorOpen] = useState(false);
    const [editingLocation, setEditingLocation] = useState(nullLocation);

    const [deleteModalState, setDeleteModalState] = useState(false);
    const [deletingLocation, setDeletingLocation] = useState('');
    const [deletingMessage, setDeletingMessage] = useState('');

    const { t } = useTranslation();

    function onLocationClick(selectedKeys, selectedNode) {
        openEditor(parseInt(selectedNode.node.key));
    }

    function openEditor(locationId) {
                if(locationId) {
            setEditingLocation(locationsArray.find( listedLocation => listedLocation.id == locationId));
        } else {
            setEditingLocation(nullLocation);
        }
        setEditorOpen(true);
    }

    function closeEditor(location) {
        if(location) {
            const foundIndex = locationsArray.findIndex(loca => loca.id == location.id);
            if(foundIndex !== -1) {
                locationsArray[foundIndex] = location;
            } else {
                locationsArray.push(location);
            }
            
            setLocationsArray(locationsArray);
            setLocationsTree(transformLocationsTree(locationsArray));
        }
        setEditorOpen(false);
    }

    function onDeletePress(event, location) {
        event.stopPropagation();
        setDeleteModalState(true);
        setDeletingLocation(location);
    }

    function deleteLocation() {
        try {
            MyItemsAxios.delete(requestLocationURL + '/' + deletingLocation.id);
            const newLocationsArray = locationsArray.filter(location => location.id !== deletingLocation.id)
            setLocationsArray(newLocationsArray);
            setLocationsTree(transformLocationsTree(newLocationsArray));
            setDeleteModalState(false);
        } catch (error) {
            console.error(error);
        }
    }

    const renderTreeNodes = (data) =>
        data.map((item) => {    
          return {
            title: (
              <span>
                {item.title} 
                <span>  <i className="bi bi-trash p-1" onClick={(e)=>{onDeletePress(e, item);}}> </i>  </span> 
                {item.googleMapsUrl && <span> <a href={item.googleMapsUrl} target="_blank" className='text-dark' onClick={(e) => {e.stopPropagation();}}> <i className='bi bi-geo-alt'> </i> </a></span>}
              </span>
            ),
            key: item.key,
          };
        });

    useEffect(() => {
        setLocationsArray(userLocations);
        setLocationsTree(transformLocationsTree(userLocations));

    }, [userLocations]);

    useEffect(() => {
        var newMessage = t('locations.delete');
        newMessage += ' "' + deletingLocation.title + '"?';
        setDeletingMessage(newMessage);
    }, [deletingLocation]);

    return (
        <>
            {locationsTree && (
                <div className="row border rounded-4 mt-3 mx-0">
                    <div className='col-11 p-3'>
                        <Tree   treeData={renderTreeNodes(locationsTree)}
                                onSelect={onLocationClick}
                                showLine
                                defaultExpandAll 
                                className='settings-page-tree'/>
                    </div>
                    <div className='col-1 m-0'>
                        <span className='btn btn-grey' onClick={()=>{openEditor(0);}}> <i className="bi bi-file-earmark-plus"></i></span>
                    </div>
                </div>
            )}
            <LocationCreator    isOpen={isEditorOpen}
                                handleClose={closeEditor}
                                location={editingLocation} />
            <ModalConfirm message={deletingMessage}
                          isOpen={deleteModalState}
                          onConfirm={deleteLocation}
                          onDecline={()=>{setDeleteModalState(false);}}/>          
        </>
    );
}

export default LocationsList;
export {requestPersonalLocationsURl, requesOrganizationtLocationsURl, manageOrganizationLocationsUrl};
export { transformLocationsTree, getLocations, getOrganizationLocations};
