import React, { useEffect, useState } from "react";
import CategoriesList from "./categoryTools/CategoriesList";
import LocationsList from "./LocationsList";
import ConfigurationPageTabs, { mainTabsSet, organizationTabsSet, personalTabsSet } from "./components/ConfigurationPageTabs";
import MemberList from "./organization/MemberList";
import PersonalInfo from "./user/PersonalInfo";
import { useNavigate, useParams } from "react-router-dom";

const Configuration = () => {

    const [activeTab, setActiveTab] = useState(null);
    const {mode, type} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if(mode !== null && type !== null) {
            if(mode === mainTabsSet.PERSONAL.path) {
                setActiveTab(mode);
            } else {
                setActiveTab(type);
            }
        }
    }, [mode, type]);

    function updateTab(selectedTab) {
        var selectedMode, selectedType;
        if(selectedTab === mainTabsSet.PERSONAL.path) {
            selectedMode = selectedTab;
            selectedType = personalTabsSet.INFO.path;
        } else {
            selectedMode = mainTabsSet.ORGANIZATION.path;
            selectedType = selectedTab;
        }
        setActiveTab(selectedTab);
        navigate(`/configuration/${selectedMode}/${selectedType}`, {replace: true});
    }

    return( <>
    { activeTab !== null &&
        <div className="container mt-5 p-4 pt-5" style={{'maxWidth': '700px'}}>
            <ConfigurationPageTabs activeTab={activeTab} onTabChange={updateTab} />
            {activeTab === organizationTabsSet.CATEGORIES.path && <CategoriesList />}
            {activeTab === organizationTabsSet.LOCATIONS.path && <LocationsList />}
            {activeTab === organizationTabsSet.USERS.path && <MemberList />}
            {activeTab === mainTabsSet.PERSONAL.path && <PersonalInfo />}
        </div>
    }
    </>);
}

export default Configuration;