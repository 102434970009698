const map = {};
const IndexesToKeysMap = {};
const endNodeMapById = {};

function findKeyByValue (obj, value) {
    const entry = Object.entries(obj).find(([key, val]) => val == value);
    return entry ? entry[0] : undefined; // return key if found, otherwise undefined
};

function getCategoryKeyById(categoryId) {
    return IndexesToKeysMap[categoryId];
}

function getCategoryIdByKey(categoryKey) {
    return findKeyByValue(IndexesToKeysMap, categoryKey);
}


function isCategoryEndByKey(categoryKey) {
    return findKeyByValue(endNodeMapById, categoryKey) !== undefined;
}

function isCategoryEndById(categoryId) {
    return endNodeMapById[categoryId] != null;
}

function fixKeyChilds(children, parentKey) {
    children.forEach((child, index) => {
        child.key = parentKey + '-' + index;
        map[child.id] = {...map[child.id], key : child.key};
        IndexesToKeysMap[child.id] = `${child.key}`;
        if(child.children) {
            fixKeyChilds(child.children, child.key);
        } else {
            endNodeMapById[child.id] = child.key;
        }
    });
};

function transformNullsToZeros(categories) {
    var processedCategories = [];
    categories.forEach(category => {
        if(category.parent == null) {
            category.parent = 0;
        }
        processedCategories.push(category);
    })
    return processedCategories;
}

function transformTree (categoriesSet) {        
    const tree = [];
    categoriesSet = transformNullsToZeros(categoriesSet);
    //sort categories
    categoriesSet.forEach((category, index) => {
        map[category.id] = { id:category.id , title: category.name, key: `${index}`, children: [] }; //...item,
    });
    categoriesSet.forEach(category => {
        if (category.parent === 0) {
            IndexesToKeysMap[category.id] = `${tree.length}`;
            tree.push(map[category.id]);
        } else {
            if (map[category.parent]) {
                map[category.parent].children.push(map[category.id]);
            }
        }
    });
    //fix keys
    tree.forEach((branch, index) => {
        branch.key = `${index}`;
        if(branch.children) {
            fixKeyChilds(branch.children, index);
        }
    });
    //mark end-nodes
    for(const category in map) {
        if(map[category].children.length === 0) {
            endNodeMapById[map[category].id] = map[category].key;
        }
    }
    //return structured array
    return tree;
}

export default transformTree;
export {getCategoryIdByKey, getCategoryKeyById, isCategoryEndByKey, isCategoryEndById, transformNullsToZeros};
