import React from "react";

export function getServiceabiltyColor(serviceable) {
    switch(serviceable) {
        case 1: //works
            return "success";

        case 2: //repair needed
            return "warning";

        case 3: //broken
            return "danger";

        default:
            return "";
    }
}

const ItemCardServiceability = ({serviceable}) => {

    const serviceabilityStyle = `col-tiny-left rounded-start-4 mx-0 p-2 bg-${getServiceabiltyColor(serviceable)}-subtle`;

    return (
        <div className={serviceabilityStyle}></div>
    );
}

export default ItemCardServiceability;