import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";

const AnyPageIconTextLinkTabs = ({ tabsSet, linkPrefix }) => {

  const [activeMainTab, setActiveMainTab] = useState(null);
  const {t} = useTranslation();

  const handleMainTabChange = (tab) => {
    setActiveMainTab(tab);
  };

  return (
      <ul className="nav nav-pills nav-justified nav-fill mb-4">
        {
          tabsSet.map((tab, index) => (
            
              <li className="nav-item nav-secondary mx-1" key={index}>
                <a href={tab.noPrefix !== true ? `${linkPrefix}/${tab.path}` : `${tab.path}`} className="link-body-emphasis link-offset-2 link-underline-opacity-0">
                  <button
                    className={`nav-link ${activeMainTab === tab.path ? "active" : ""}`}
                    onClick={() => handleMainTabChange(tab.path)}
                  >
                    <div className=""> 
                      <i className={`bi ${tab.iconBi} h1`}> </i>
                    </div>
                    {t(tab.translateTag)}
                  </button>
                  </a>
                </li>
          ))
        }
      </ul>
  );
};

export default AnyPageIconTextLinkTabs;
