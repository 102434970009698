import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { isUserManagerOrOwner } from "../user/UserRoleChecks";
import { useTranslation } from "react-i18next";

export const mainTabsSet = {
  PENDING : {
    path : 'active',
    translationTag : 'tab.pending'
  },
  HISTORY : {
    path : 'history',
    translationTag : 'tab.history'
  }
}

export const subTabsSet = {
  SENT : {
    path : 'personal',
    translationTag : 'tab.sent'
  },
  RECEIVED : {
    path : 'manager',
    translationTag : 'tab.received'
  }

}

const ItemTransferRequestTabs = ({requestsType, requestsMode, onTabChange }) => {
  const [activeMainTab, setActiveMainTab] = useState(requestsMode);
  const [activeSubTab, setActiveSubTab] = useState(requestsType);

  const { t } = useTranslation();

  const handleMainTabChange = (tab) => {
    setActiveMainTab(tab);
    setActiveSubTab(subTabsSet.SENT.path);
    onTabChange(tab);
  };

  const handleSubTabChange = (tab) => {
    setActiveSubTab(tab);
    onTabChange(tab);
  };

  return (
    <div className="my-2">
      <ul className="nav nav-pills nav-justified nav-fill nav-primary">
        {/* Основные вкладки */
        Object.values(mainTabsSet).map( (tab, index) => (
          <li className="nav-item rounded mx-1" key={index}>
            <a 
              href={`/organization/itemTransferRequestList/${tab.path}/1`}
              className={`button nav-link ${activeMainTab === tab.path ? "active" : ""}`}
              onClick={() => handleMainTabChange(tab.path)}
            >
              {t(tab.translationTag)}
            </a>
          </li>
        ))}
      </ul>
      
      {/* Подвкладки для отправленных/полученных запросов  */}
      {isUserManagerOrOwner() && (
        <ul className="nav nav-pills nav-justified nav-fill nav-secondary mt-2">
          {
            Object.values(subTabsSet).map( (tab, index) => (
              <li className="nav-item rounded mx-1" key={index}>
                <button
                  className={`nav-link ${activeSubTab === tab.path ? "active" : ""}`}
                  onClick={() => handleSubTabChange(tab.path)}
                >
                  {t(tab.translationTag)}
                </button>
              </li>
            ))
          }
        </ul>
      )}
    </div>
  );
};

export default ItemTransferRequestTabs;
