const userConfigName = 'userConfig';

export const basicConfigStorage = {
    mainPageSize : 3,
    searchPageSize : 5
}

export function setConfigStorage(configStorage) {
    localStorage.setItem(userConfigName, JSON.stringify(configStorage));
}

export function getConfigStorage() {
    const configStorage = localStorage.getItem(userConfigName);
    if(configStorage) {
        return JSON.parse(configStorage); 
     } else {
        return basicConfigStorage;
     }
}