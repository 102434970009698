import React, {useState} from "react";
import { useTranslation } from 'react-i18next';
import NoteForm from "../../components/NoteForm";
import TimeLeft from "../../components/TimeLeft";
import ModalConfirm from "../../components/ModalConfirm";
import MyItemsAxios from "../../backendTools/MyItemsAxios";

function ItemNoteManager ({itemId, noteList}) {
    const noteRemoveUrl = "/note";
    const [notesList, updateNotes] = useState(noteList.sort((a, b) => a.id < b.id ? 1 : -1));
    const [isModalsOpen, setModalsOpen] = useState(noteList.length ? Array(noteList.length).fill(false) : []);
    const { i18n, t } = useTranslation();
    const userLocale = i18n.language;
    
    const removeNote = async (noteId) => {
        closeNoteDeleteModalById(noteId);
        await MyItemsAxios.delete(noteRemoveUrl + '/' + noteId);
        updateNotes(notesList.filter(note => note.id !== noteId));
    }

    const addNewNote = (newNote) => {
        updateNotes([newNote, ...notesList]);
        isModalsOpen.unshift(false);
        closeNoteDeleteModalById(newNote.id);
    }

    const openNoteDeleteModal = (index) => {
        const newModalStates = isModalsOpen.map((modal, arrayIndex) => 
            arrayIndex === index ? true : modal);
        setModalsOpen(newModalStates);
    }

    const closeNoteDeleteModalById = (id) => {
        const index = notesList.findIndex(note => note.id === id);
        closeNoteDeleteModalByIndex(index);
    }

    const closeNoteDeleteModalByIndex = (index) => {
        const newModalStates = isModalsOpen.map((modal, arrayIndex) => 
            arrayIndex === index ? false : modal);
        setModalsOpen(newModalStates);
    }

    return (
        <div>
            <div className='row'> 
                <NoteForm itemId={itemId} addNoteOnPage={addNewNote}/>
                {(notesList != null) && notesList.map((note,index) => (
                    <div key={index} className="card rounded-4 p-0 mb-3">
                        <div className="card-header rounded-top-4 row m-0 p-2 bg-dark-subtle bg-gradient justify-content-between">
                            {note.author != null ? (
                                    <>
                                        <span className="col-auto text-secondary"><i className="bi bi-person h5"></i> {note.author} </span>
                                        <span className="col-auto text-secondary"><TimeLeft targetDate={note.date} localeCode={userLocale}/> </span>
                                    </>
                                ) : (
                                        <span className="col-auto text-secondary"><TimeLeft targetDate={note.date} localeCode={userLocale}/> </span>
                            )}
                            <span className="col-2 d-flex flex-fill  justify-content-end">  
                                <i className="text-dark bi bi-trash"  style={{ cursor: 'pointer' }}
                                    onClick={(e)=>{e.preventDefault(); openNoteDeleteModal(index);}} ></i>
                            </span>
                        </div>
                        <div className="bg-light-grey rounded-bottom-4 ps-3 pt-2">
                            <blockquote className="">
                                {note.content}
                            </blockquote>
                        </div>
                        <ModalConfirm   message={t('delete.this.note')}
                                        isOpen={isModalsOpen[index]}
                                        onConfirm={removeNote}
                                        onDecline={closeNoteDeleteModalById}
                                        props={note.id}/>
                    </div>
                ))}
            </div>
        </div>       
    );
}

export default ItemNoteManager;