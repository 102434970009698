import React, { useEffect, useState } from "react";
import MyItemsAxios from "../backendTools/MyItemsAxios";
import AdminButtons from "./AdminButtons";
import {Row, Col, Card } from 'react-bootstrap';
import { isUserAdmin } from "../user/UserRoleChecks";

const UserList = () => {

    const [usersList, setUserList] = useState([]);

    useEffect(() => {
        async function getUsers() {
            try {
                const response = await MyItemsAxios.get('/admin/get-all-users');
                setUserList(response.data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        }
        
        getUsers();

    }, []);

    function deactivateUser(userId) {
        MyItemsAxios.put('/admin/' + userId + '/deactivate');
    }

    function activateUser(userId) {
        MyItemsAxios.put('/admin/' + userId + '/activate');
    }

    function handleBlockButton(userId) {
        const userIndex = usersList.findIndex(user => user.id === userId);
        if(userIndex != -1) {
            if(usersList[userIndex].activated) {
                deactivateUser(userId);
            } else {
                activateUser(userId);
            }
            window.location.reload();
        }
    }

    function getHighlightClass(user) {
        if(user.role == "admin") {
            return 'table-warning';
        }
        if(user.activated) {
            return 'table-success';
        } else  {
            return 'table-info';
        }
    }


return (
    <div className="container mt-5 p-3 bg-light" style={{'maxWidth':'900px'}}>
            <Row>
                <Col xs={6}><Card><a href="/admin/invite"> <Card.Body>Invite new User </Card.Body></a> </Card></Col>
                <Col xs={6}><Card><a href="#"> <Card.Body>Spare Btn </Card.Body></a> </Card></Col>
            </Row>
            <table className="table table-stripped">
                <thead>
                    <tr>
                        <td> ID </td>
                        <td> Username </td>
                        <td> E-mail </td>
                        <td> Roles </td>
                        <td> Status </td>
                        <td> Actions </td>
                    </tr>
                </thead>
                <tbody>
                {usersList && usersList.map((user, index) => (
                    <tr key={index} className={getHighlightClass(user)}>
                        <td> {user.id} </td>
                        <td> {user.username} </td>
                        <td> {user.email} </td>
                        <td> {user.roles} </td>
                        <td> {user.activated ? "Activated" : "Registered"} </td>
                        {isUserAdmin() && 
                            <td> <AdminButtons   userId={user.id} 
                                                userActivated={user.activated} 
                                                userActivationControl={handleBlockButton}/> 
                            </td>
                        }
                        
                    </tr>
                )) }
                </tbody>
            </table>
    </div>
);
}

export default UserList;