import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getConfigStorage, setConfigStorage } from "../user/ConfigStorage";

const ConfigPageSettings = () => {
    const { t } = useTranslation();
    const [userConfig, setUserConfig] = useState(getConfigStorage());
    const [alertVisible, setAlertVisible] = useState(false);

    function updateConfig(event) {
        event.preventDefault();
        setConfigStorage(userConfig);
        setAlertVisible(true);
        setTimeout(() => setAlertVisible(false), 4000);   
    }

    function handleChange (event) {
        const { name, value } = event.target;
        setUserConfig({ ...userConfig, [name]: value });
      };

    return (
        <div className="row mt-3 mx-0"> 
            <div  className={`alert alert-secondary floating-alert mt-3 ${alertVisible ? "show" : "fade"}`}
              role="alert">
                {t('alert.updated.data')}
            </div>
        <form className="col-12 row">
            <hr className=""/>
            <div className="col-12 mb-4 d-flex flex-row-reverse">
                <button onClick={updateConfig} className="btn btn-outline-secondary">
                        <i className='bi bi-floppy h5'> </i>
                </button>
            </div>
            
            <div className="col-6"> 
                <label htmlFor="mainPageSize">
                    {t('label.mainPageSize')} : 
                    <input type="number" name="mainPageSize" 
                        value={userConfig.mainPageSize} className="form-control"
                        onChange={handleChange}/>
                </label>
            </div>
            <div className="col-6">
                <label htmlFor="searchPageSize">
                    {t('label.searchPageSize')} :
                <input type="number" name="searchPageSize" 
                    value={userConfig.searchPageSize} className="form-control"
                    onChange={handleChange}/>
                </label>
            </div>
        </form>
        </div>
      );
  };

  export default ConfigPageSettings;
