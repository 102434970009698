import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import backendHost from "../backendTools/backendHost";
import axios from "axios";

const minPasswordLength = 5;

const RestorePassword = ({onLogin}) => {

    const [alertMessage, setAlertMessage] = useState(null);
    const [isRequesting, setRequesting] = useState(false);
    const [isUpdating, setUpdating] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [restoreKey, setRestoreKey] = useState(null);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [isMailValid, setMailValid] = useState(true);
    const [isPasswordDoubled, setPasswordDoubled] = useState(true);
    const [isPasswordShort, setPasswordShort] = useState(false);

    useEffect(()=>{
        const receivedKey = searchParams.get("restore-password-code");
        if(receivedKey != undefined) {
            setRestoreKey(receivedKey);
            setUpdating(true);
            setRequesting(false);
        } else {
            setRequesting(true);
            setUpdating(false);
        }
    }, [searchParams]);

    function isEmailValid(email) {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }

    function validateEmail(event) {
        const newMailValue = event.currentTarget.value.trim();
        if(newMailValue !== '') {
            var isMailValid = isEmailValid(newMailValue);
            setMailValid(isMailValid);
            if(isMailValid) {
                
            }
        } else {
            setMailValid(true);
        }
    }

    function validatePasses() {
        const pass1 = document.getElementById("password1").value;
        const pass2 = document.getElementById("password2").value;
        if(pass2) {
            if(pass1 === pass2) {
                setPasswordDoubled(true);
            } else {
                setPasswordDoubled(false);
            }
        }
        if(pass1.length < minPasswordLength) {
            setPasswordShort(true);
        } else {
            setPasswordShort(false);
        }
    }

    async function requestPasswordRestore(event) {
        event.preventDefault();
        const mail = document.getElementById("email").value;
        const userMail = mail.trim();
        try {
            const response = await axios.post(backendHost + '/user/forgot-password', userMail, {
                headers: {
                  'Content-Type': 'text/plain'
                }});
            setAlertMessage(t('label.success') + ', ' + response.data + ', ' + t('email.check'));
        } catch(error) {
            setAlertMessage(t('email.error'));
        }
    }

    async function sendNewPassword(event) {
        event.preventDefault();
        const pass = document.getElementById("password1").value;
        const response = await axios.put(backendHost + '/user/update-password?restore-password-code=' + restoreKey, pass, {
            headers: {
              'Content-Type': 'text/plain'
            }});
        if(response.status == 200) {
            onLogin(response.data);
            navigate('/');
        } else {
            setAlertMessage(t('wrong.something'));
        }
    }

    return (
        <div className="container py-3 py-md-5 py-lg-5" style={{'maxWidth': '700px'}}>
            <div className="row p-1 p-lg-4 justify-content-center">
                <div className="col-12 col-md-8 col-lg-8 p-3 p-md-4 p-lg-4 bg-light rounded-4">
        {isRequesting && 
            <> 
                {alertMessage && <div className="alert alert-warning alert-dismissible fade show" role="alert">
                                    {alertMessage}
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" onClick={()=>{setAlertMessage(null);}} aria-label={t('action.close')}></button>
                                </div>}
                    <form className="p-2" onSubmit={requestPasswordRestore}> 
                        <div className="p-2">
                            {t('email.enter')}
                        </div>
                        <div className="p-2">
                            <input  type="text" 
                                    id="email"
                                    className="form-control" 
                                    placeholder={t('label.email')}
                                    onBlur={validateEmail}
                                    required/>
                            {!isMailValid && <p style={{ color: 'red' }}>{t('invalid.email')}</p>}
                        </div>
                        <div className="p-2">
                            <button type="submit"  className="form-control btn btn-outline-success"> {t('password.request.new')} </button>
                        </div>                        
                    </form>
                </>
        }
        {isUpdating && 
            <>
            {alertMessage && 
                <div className="alert alert-warning alert-dismissible fade show" role="alert">
                    {alertMessage}
                    <button type="button" className="btn-close" data-bs-dismiss="alert" onClick={()=>{setAlertMessage(null);}} aria-label={t('action.close')}/>
                </div>
            }
                <form className="p-2" onSubmit={sendNewPassword}> 
                    <div className="p-2">
                        {t('password.create')}
                    </div>
                    <div className="p-2">
                        <input  type="password" 
                                id="password1"
                                className="form-control" 
                                placeholder={t('label.password')}
                                onBlur={validatePasses}
                                required/>
                        {isPasswordShort && <p style={{ color: 'red' }}>{t('password.is.too.short')}</p>}
                    </div>
                    <div className="p-2">
                        <input  type="password" 
                                id="password2"
                                className="form-control" 
                                placeholder={t('label.password')}
                                onBlur={validatePasses}
                                required/>
                        {!isPasswordDoubled && <p style={{ color: 'red' }}>{t('enter.the.same.password')}</p>}
                    </div>
                    <div className="p-2">
                        <button type="submit"  className="form-control btn btn-outline-success"> {t('action.confirm')} </button>
                    </div>                        
                </form>
            </>
        }
        </div>
        </div>
        </div>
    );

}

export default RestorePassword;