import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import MyItemsAxios from "../backendTools/MyItemsAxios";
import { useLocation, useNavigate } from "react-router-dom";

function ItemTransferRequestCreator({ itemId, managerList }) {
    const [selectedManager, setSelectedManager] = useState('');
    const [requestDescription, setRequestDescription] = useState('');
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    // Create request
    async function sendRequest (data) {
        data.itemId = itemId;
        await MyItemsAxios.post("/organization/item-transfer-request", data);
        navigate(`/item/${itemId}/requests`, { state: { from: location.pathname } });
    };
  
    const handleConfirm = () => {
      if (selectedManager && requestDescription) {
        // You can use the selected person and description here
        sendRequest({ managerId: parseInt(selectedManager), description:  requestDescription});
      } else if (!selectedManager) {
        alert(t('error.select_person')); // show error message when no person is selected
      } else if (!requestDescription) {
        alert(t('error.enter_description')); // show error message when description field is empty
      }
    };
  
    return (
      <form className="m-1 p-1">
        <div className="input-group">
          <i className="bi bi-person-check h5 m-2 pe-1"> </i>
          <select className="form-select" aria-label={t('request.select_person')} 
                  name="stateOfServiceability" onChange={(e) => setSelectedManager(e.target.value)}>
            <option value=''> </option> 
            {managerList.map((person) => (
              <option key={person.id} value={person.id}>{person.username}</option>
            ))}
          </select>
        </div>
        <div className="input-group my-2">
          <i className="bi bi-info-square h5 m-2 pe-1"> </i>
          <textarea
            className="form-control mt-2"
            onChange={(e) => setRequestDescription(e.target.value)}
            placeholder={t('placeholder.description')}
            rows={2}>
          </textarea> 
        </div>
        <div className="d-flex flex-row-reverse me-4 mt-3">
          <div className="btn btn-outline-secondary my-1 ms-2" onClick={handleConfirm}> <i className={"bi bi-send h5"}></i> </div>
        </div>
        
      </form>
    );
  }

  export default ItemTransferRequestCreator;
