import { QRCodeCanvas } from "qrcode.react";

const QrGenerator = ({ value, qrRef, clickHandler}) => {
    return (
      <div ref={qrRef} className="d-flex justify-content-center m-3">
        <QRCodeCanvas value={value} onClick={clickHandler} size={320} />
      </div>
    );
  };
  
export default QrGenerator;
