import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import AnyPageIconTextTabs from "./AnyPageIconTextTabs";

export const mainTabsSet = {
  PERSONAL : {
    path : 'personal',
    translateTag : 'tab.personal'
  },
  ORGANIZATION : {
    path : 'organization',
    translateTag : 'tab.organization'
  }
};

export const organizationTabsSet = {
  CATEGORIES : {
    path : 'categories',
    translateTag : 'config.categories',
    iconBi : 'bi-diagram-3'
  },
  LOCATIONS : {
    path : 'locations',
    translateTag : 'config.locations',
    iconBi : 'bi-geo-alt'
  },
  USERS : {
    path : 'users',
    translateTag : 'tab.users',
    iconBi : 'bi-person'
  }
};

export const personalTabsSet = {
  INFO : {
    path : 'info',
    translateTag : 'tab.info'
  }
}

const ConfigurationPageTabs = ({ activeTab, onTabChange }) => {

  const {t} = useTranslation();

  const [activeMainTab, setActiveMainTab] = useState(activeTab === mainTabsSet.PERSONAL.path ? activeTab : mainTabsSet.ORGANIZATION.path);
  const [activeSubTab, setActiveSubTab] = useState(activeTab);

  const handleMainTabChange = (tab) => {
    setActiveMainTab(tab);
    if(tab === mainTabsSet.ORGANIZATION.path) {
        tab = organizationTabsSet.CATEGORIES.path;
        setActiveSubTab(tab);
    } else {
        setActiveSubTab(null);
    }
    onTabChange(tab);
  };

  const handleSubTabChange = (tab) => {
    setActiveSubTab(tab);
    onTabChange(tab);
  };

  

  if(activeTab === null) {
    return ;
  }

  return (
    <div>
      {/* Основные вкладки */}
      <ul className="nav nav-pills nav-justified nav-fill">
        {
          Object.values(mainTabsSet).map((tab, index) => (
            <li className="nav-item nav-primary mx-1" key={index}>
              <button
                className={`nav-link ${activeMainTab === tab.path ? "active" : ""}`}
                onClick={() => handleMainTabChange(tab.path)}
              >
                {t(tab.translateTag)}
              </button>
            </li>
          ))
        }
      </ul>
      
      {/* Подвкладки для Организации */}
      {activeMainTab === "organization" && (
        <AnyPageIconTextTabs 
          tabsSet={Object.values(organizationTabsSet)}
          onTabChange={handleSubTabChange}
          activeTab={activeSubTab}/>
      )}
    </div>
  );
};

export default ConfigurationPageTabs;
