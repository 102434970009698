import React from "react";
import { useTranslation } from 'react-i18next';

const LanguageInNavBarToggler = () => {

    const { t, i18n } = useTranslation();

    function changeLanguage(lng) {
        i18n.changeLanguage(lng);
    };


    return (
        <div className="navbar-nav  ms-auto mx-3">
            <li className="nav-item dropdown">
            <button className="btn btn-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                {t('label.language')}
            </button>
            <ul className="dropdown-menu dropdown-menu-dark" id="language-toggler">
                <li><span className="dropdown-item" style={{ cursor: 'pointer' }} onClick={()=>{changeLanguage('en');}}>EN</span></li>
                <li><span className="dropdown-item" style={{ cursor: 'pointer' }} onClick={()=>{changeLanguage('ru');}}>RU</span></li>
                <li><span className="dropdown-item" style={{ cursor: 'pointer' }} onClick={()=>{changeLanguage('ua');}}>UA</span></li>
            </ul>
            </li>
        </div>
    );
}

export default LanguageInNavBarToggler;

