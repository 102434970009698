import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const BackButton = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Определяем источник перехода
  const previousPage = location.state?.from;

  const handleGoBack = () => {
    navigate(location.state?.from || '/page/1');
  };

  return (
    <button
      onClick={handleGoBack}
      className="btn"
    >
      <i className="bi bi-arrow-90deg-left"></i> 
    </button>
  );
};

export default BackButton;
