// ItemTransferRequestService.js
import MyItemsAxios from "./MyItemsAxios"; 

const API_GET_SINGLE_ITEM_TRANSFER_REQUEST = '/organization/item-transfer-request/';

const API_GET_SINGLE_ITEM_TRANSFER_REQUEST_BY_ITEM_ID = '/organization/item-transfer-request/item/';

const API_UPDATE_ITEM_TRANSFER_REQUEST_PART1 = 'organization/manager/item-transfer-request/';
const API_UPDATE_ITEM_TRANSFER_REQUEST_PART2 = '/is-approved/';

const API_PERSONAL_REQUESTS = '/organization/item-transfer-request/personal-active';
const API_PERSONAL_HISTORY  = '/organization/item-transfer-request/personal-history';
const API_MANAGER_REQUESTS  = '/organization/manager/item-transfer-request/request-active';
const API_MANAGER_HISTORY   = '/organization/manager/item-transfer-request/request-history';

async function getItemTransferRequestPage(ApiUrl, pageNumber, pageSize) {
  //apply default values if not given value
  if(pageNumber === undefined || pageNumber === null) {
    pageNumber=0;
  }
  if(pageSize === undefined || pageSize === null) {
    pageSize=5;
  } 
  //process request to backend
  try {
    const response = await MyItemsAxios.get(`${ApiUrl}?page-number=${pageNumber}&page-size=${pageSize}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching item transfer requests:', error);
    throw error;
  }
}

export async function getItemTransferRequest(requestId) {
  try {
    const response = await MyItemsAxios.get(`${API_GET_SINGLE_ITEM_TRANSFER_REQUEST}${requestId}`);
    return response.data;
  } catch (error) {
    console.error('Error updating item transfer request:', error);
    throw error;
  }
};

export async function getItemTransferRequestByItemId(itemId) {
  try {
    const response = await MyItemsAxios.get(`${API_GET_SINGLE_ITEM_TRANSFER_REQUEST_BY_ITEM_ID}${itemId}`);
    return response.data;
  } catch (error) {
    console.error('Error updating item transfer request:', error);
    throw error;
  }
};

export async function updateItemTransferRequest(requestId, action) {
  try {
    const response = await MyItemsAxios.put(`${API_UPDATE_ITEM_TRANSFER_REQUEST_PART1}${requestId}${API_UPDATE_ITEM_TRANSFER_REQUEST_PART2}${action}`);
    return response.data;
  } catch (error) {
    console.error('Error updating item transfer request:', error);
    throw error;
  }
};

export async function getPersonalItemTransferRequests(pageNumber, pageSize) {
  return getItemTransferRequestPage(API_PERSONAL_REQUESTS, pageNumber, pageSize);
};

export async function getManagerItemTransferRequests(pageNumber, pageSize) {
  return getItemTransferRequestPage(API_MANAGER_REQUESTS, pageNumber, pageSize);
};

export async function getPersonalItemTransferRequestHistory(pageNumber, pageSize) {
  return getItemTransferRequestPage(API_PERSONAL_HISTORY, pageNumber, pageSize);
};

export async function getManagerItemTransferRequestHistory(pageNumber, pageSize) {
  return getItemTransferRequestPage(API_MANAGER_HISTORY, pageNumber, pageSize);
};