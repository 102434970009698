import React from "react";

function ItemName ({name, isEditing, updateName}) {

    function onNameChange(event) {
        if(updateName) {
            updateName(event.target.value);
        }
    }
    return (
        <>
        {isEditing ? 
        <div className="col-auto input-group m-2 pe-3">
            <i className="bi bi-clipboard-check h5 p-1"> </i>
            <input  type="text" 
                    id="name"
                    className="form-control"
                    value={name}
                    onChange={onNameChange}/> 
        </div>
            
                    
            :
            <span className="h3 text-wrap"> {name}</span>
        }
        </>
    );
}

export default ItemName;