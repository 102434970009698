import React from "react";
import { getUserData } from "./UserStorage";
import { useTranslation } from "react-i18next";
import ConfigPageSettings from "../organization/ConfigPageSettings";

const PersonalInfo = () => {
    const { t } = useTranslation();
    const user = getUserData();

    return (
          <div className="row border rounded-4 mt-5 p-3">
            <div className="col-12">
                <span className="h6">{t('label.username')}:</span>
                <span> {user.username} </span>
            </div>
            <div className="col-12">
                <span className="h6">{t('label.email')}:</span>
                <span> {user.email} </span>
            </div>
            <div className="col-12">
                <span className="h6">{t('label.organization')}:</span>
                <span> {user.organizationName} </span>
            </div>
            <ConfigPageSettings />
          </div>
      );
  };

  export default PersonalInfo;
