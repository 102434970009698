import React from "react";
import { useTranslation } from 'react-i18next';

function ItemDeliveryStatus ({status}) {
    const { t } = useTranslation();

    var deliveryStatus = "";
    var deliveryColor = "";
    const isFieldNull = status == null;
    if(!isFieldNull) {
        deliveryStatus = status ? t('delivery.submit') : t('delivery.stored');
        deliveryColor = status ? "text-success" : "text-primary"; 
    }
    

    return (
        <>
            { !isFieldNull && (
                <div className='col-auto mini-box m-2'> 
                    <i className="bi bi-boxes"></i>
                    <span className={deliveryColor}> {deliveryStatus} </span> 
                </div>
            )}
        </>
    );
}

export default ItemDeliveryStatus;