const organizationRoles = {
    OWNER: 'OWNER',
    MANAGER: 'MANAGER',
    MEMBER: 'MEMBER'
};

export function getOrganizationRolesArray() {
    return Object.values(organizationRoles)
}

export default organizationRoles;
