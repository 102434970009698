import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";

const AnyPageIconTextTabs = ({ activeTab, onTabChange, tabsSet }) => {

  const [activeMainTab, setActiveMainTab] = useState(activeTab);
  const {t} = useTranslation();

  const handleMainTabChange = (tab) => {
    setActiveMainTab(tab);
    onTabChange(tab);
  };
  
  if(activeTab === null) {
    return ;
  }

  return (
      <ul className="nav nav-pills nav-justified nav-fill mt-1">
        {
          tabsSet.map((tab, index) => (
            <li className="nav-item nav-secondary mx-1" key={index}>
                <button
                  className={`nav-link ${activeMainTab === tab.path ? "active" : ""}`}
                  onClick={() => handleMainTabChange(tab.path)}
                >
                  <div className="m-1"> 
                    <i className={`bi ${tab.iconBi} h4`}> </i>
                  </div>
                   {t(tab.translateTag)}
                </button>
              </li>
          ))
        }
      </ul>
  );
};

export default AnyPageIconTextTabs;
