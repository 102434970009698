// Request.js
import React, { useEffect } from 'react';
import { getUserName } from '../user/UserStorage';
import { useLocation, useNavigate } from 'react-router-dom';

const ItemTransferRequestDecisionMaker = ({ itemTransferRequest, onAction, showButtons }) => {
  const navigate = useNavigate();
  const location = useLocation();
  
  async function handleActionClick (action) {
    if(onAction) {
      onAction(itemTransferRequest.id, action);
    }
  };

  function onLinkClick() {
    if(!showButtons){
      navigate(`/item/${itemTransferRequest.itemId}/requests/${itemTransferRequest.id}`, { state: { from: location.pathname } })
    }
  }

  useEffect(()=>{}, [showButtons]);

  return (
    <div className="card mb-3 bg-light">
        <div className={`card-body`} onClick={onLinkClick}>
            <div className='row'>
              <div className='row mt-2'>
                  <div className='col-12 m-1'>
                      <i className="bi bi-person h6"> </i>
                      <span>{itemTransferRequest.receiverName}</span>
                  </div>
                  <div className='col-12 m-1'>
                      <i className="bi bi-person-check h6"> </i>
                      <span>{itemTransferRequest.managerName}</span>
                  </div>
                  <div className='col-12 m-1'>
                      <i className="bi bi-info-square h6"> </i>
                      <span>{itemTransferRequest.description}</span>
                  </div>
                  {showButtons && 
                    <div className='col-12 m-1'>
                      <i className="bi bi-calendar-date h6"> </i>
                      <span>{new Date(itemTransferRequest.creationDate).toLocaleString()}</span>
                    </div>
                  }
              </div>
            </div>
          {showButtons && (getUserName() === itemTransferRequest.managerName) && (
              <div className='row'>
                <button onClick={() => handleActionClick(true)} className="col-5 btn btn-outline-success mx-2"><i className="bi bi-check-circle h5"></i></button>
                <button onClick={() => handleActionClick(false)} className="col-5 btn btn-outline-danger mx-2"><i className="bi bi-x-circle h5"></i></button>
              </div>
            )}
        </div>
    </div>
  );
};

export default ItemTransferRequestDecisionMaker;