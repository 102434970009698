import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import ItemImage from "./ItemImage";
import ItemName from '../itemTools/ItemName';
import ItemAssignee from "./ItemAssignee";
import ItemCardServiceability, { getServiceabiltyColor } from "./ItemCardServisability";

const ItemCard = ({item}) => {
    const navigate = useNavigate();
    const location = useLocation();

    function handleClick() {
        navigate("/item/"+item.id, { state: { from: location.pathname } });
    }

    const cardClasses = `card my-2 p-0 rounded-4 border border-${getServiceabiltyColor(parseInt(item.stateOfServiceability))}-subtle`;

    return (
        <div className={cardClasses} onClick={handleClick} style={{ cursor: 'pointer' }}>
            <div className="row g-0 p-0">
                <ItemCardServiceability serviceable={parseInt(item.stateOfServiceability)} />
                <div className="col-4  p-2">
                    <ItemImage photo={item.photo}/>
                </div>
                <div className="col-7">
                <div className="card-body ps-3 py-1 ps-md-5">
                    <div className="card-title pt-3 mx-2">
                        <ItemName name={item.name}/>
                    </div>
                    <div className="card-text">
                        <ItemAssignee itemUser={item.user} />
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
}

export default ItemCard;
