import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

export const searchTabsSet = {
  COMMON : {
    path : 'common',
    iconBi : 'bi-wrench-adjustable-circle'
  },
  CATEGORIES : {
    path : 'categories',
    iconBi : 'bi-diagram-3'
  }, 
  TIME_PARAMETERS : {
    path : 'time',
    iconBi : 'bi-clock-history'
  }
};

const SearchTabs = ({ activeTab, onTabChange }) => {

  const [activeMainTab, setActiveMainTab] = useState(activeTab);


  const handleMainTabChange = (tab) => {
    setActiveMainTab(tab);
    onTabChange(tab);
  };
  
  if(activeTab === null) {
    return ;
  }

  return (
    <div>
      <ul className="nav nav-pills nav-fill">
        {
          Object.values(searchTabsSet).map((tab, index) => (
            <li className="nav-item nav-primary mx-1" key={index}>
              <button
                className={`nav-link ${activeMainTab === tab.path ? "active" : ""}`}
                onClick={() => handleMainTabChange(tab.path)}
              >
                <i className={"bi " + tab.iconBi + " h5"}></i>
              </button>
            </li>
          ))
        }
      </ul>
    </div>
  );
};

export default SearchTabs;
