import React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './Landing.css';

function Landing() {

  const { t } = useTranslation();
  
  return (
    <div>
      <br/>
      <Container fluid className="jumbotron text-center my-1 my-md-3 my-lg-4 rounded-4">
          <div className='d-block'>
            <p> <br/> </p>
          </div>
          <h2>{t('main.welcome')}</h2>
          <div className='d-block'>
            <p> <br/> </p>
          </div>
          <div className='d-block'>
            <p> <br/> </p>
          </div>
          <div className='d-none d-md-block'>
            <p> <br/></p>
          </div>
          <div className='d-none d-lg-block'>
            <p> <br/></p>
          </div>
          <div className='d-none d-xl-block'>
            <p> <br/></p>
          </div>
          <p>{t('main.description')}</p>
          <div className='d-block'>
            <p> <br/> </p>  
          </div>
      </Container>
    </div>
  );
}

export default Landing;
