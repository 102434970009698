import React, {useState, useEffect, useContext} from 'react';
import MyItemsAxios from '../backendTools/MyItemsAxios';
import { useParams, useLocation } from 'react-router-dom';

import PageScroll from '../components/PageScroll';
import RenderListItems from '../RenderListItems';
import { ApplicationContext } from '../App';
import { getConfigStorage } from '../user/ConfigStorage';

const requestSearchUtl = "/organization/item/search"

const pageNumberPropertyName = "page-number";
const pageSizePropertyName = "page-size";
const categoryFilterPropertyName = "category";
const locationFilterPropertyName = "location-id";
const conditionFilterPropertyName = "condition";

const defaultPageSize = getConfigStorage().searchPageSize;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ListItems({userPageSize}) {
  const{userLocations, userCategories} = useContext(ApplicationContext);

  const controller = new AbortController();
  const [itemsPagingData, setItemsPagingData] = useState(null);
  const timeoutId = setTimeout(() => controller.abort(), 30000);  // 30 seconds
  const [categories, setCategoriesList] = useState(null);
  const [locationsList, setLocationsList] = useState(null);
  
  var { pageNumber } = useParams();
  const query = useQuery();
  const categoryIdSet = query.get('category');
  const locationIdSet = query.get('location-id');
  const conditionSet = query.get('condition');
  const createTimeStart = query.get('create-start') || '';
  const createTimeEnd = query.get('create-end') || '';
  const updateTimeStart = query.get('update-start') || '';
  const updateTimeEnd = query.get('update-end') || '';
  const userSelected = query.get('item-user') || '';

  if(!pageNumber) {
    pageNumber = 1;
  }

  useEffect(() => {

    function getItemsPagingUrl(pageNumber, pageSize) {
        const pageNumberRequest = pageNumberPropertyName + '=' + pageNumber;
        const pageSizeRequest = pageSizePropertyName + '=' + (pageSize ? pageSize : defaultPageSize);
        const categoryRequest = categoryFilterPropertyName + '=' + (categoryIdSet!=null ? categoryIdSet : '');
        const locationRequest = locationFilterPropertyName + '=' + (locationIdSet!=null ? locationIdSet : '');
        const conditionRequest = conditionFilterPropertyName + '=' + (conditionSet!=null ? conditionSet : '');
        const createdDateRequest = `start-created-date=${createTimeStart}&end-created-date=${createTimeEnd}`;
        const updatedDateRequest = `start-updated-date=${updateTimeStart}&end-updated-date=${updateTimeEnd}`;
        const itemUserRequest = `item-user=${userSelected}`;
        return (requestSearchUtl + '?' + 
          pageNumberRequest + 
          '&' + pageSizeRequest + 
          '&' + categoryRequest +
          '&' + locationRequest + 
          '&' + conditionRequest +
          '&' + createdDateRequest + 
          '&' + updatedDateRequest +
          '&' + itemUserRequest);      
    }

    // Function to fetch data
    const fetchData = async () => {
      
      clearTimeout(timeoutId);
      var pageRequestURl = getItemsPagingUrl(pageNumber-1, userPageSize);
      try {
        const response = await MyItemsAxios.get(pageRequestURl);
        setItemsPagingData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    setCategoriesList(userCategories);
    setLocationsList(userLocations);
    fetchData();
  }, [categoryIdSet, userCategories, userLocations]); 

  if(itemsPagingData === null) {
    return (<></>);
  }
  return (
  <div className="container align-content-center mt-5 p-3" style={{'maxWidth': '700px'}}>
    <div className='row'>
      <PageScroll totalPages={parseInt(itemsPagingData.page.totalPages)} currentPage={parseInt(pageNumber)} query={query} defaultPath={"/page/"}/>
      <RenderListItems itemsPagingData={itemsPagingData} categoriesList={categories} locationsList={locationsList} />
      <PageScroll totalPages={parseInt(itemsPagingData.page.totalPages)} currentPage={parseInt(pageNumber)} query={query} defaultPath={"/page/"}/>
    </div>
  </div>);
}

export default ListItems;
