import React, { useEffect, useState } from "react";
import { Tree } from "antd";
import { useTranslation } from 'react-i18next';
import transformTree, {getCategoryIdByKey, getCategoryKeyById, isCategoryEndByKey} from '../categoryTools/CategoryTree';

const ItemCategoriesSelect = ({onClose, categoryList, categoriesOfItem, handleAddCategory}) => {

    const [categoryTree, setTree] = useState(null);
    const [checkedKeys, setCheckedKeys] = useState([]);
    const { t } = useTranslation();

    function handleConfirm () {
        var selectedCategories = [];
        //get categoryIDs by keys
        checkedKeys.forEach(categoryKey => {
            if(isCategoryEndByKey(categoryKey)) {
                selectedCategories.push(getCategoryIdByKey(categoryKey));
            }
        });
        
        handleAddCategory(selectedCategories);
        if(onClose != null) {
            onClose();
        }
    }
      
    const onCheck = (checkedKeysValue) => {
        setCheckedKeys(checkedKeysValue);
    };
    

    useEffect(()=>{
        if(categoryList) {
            var selectedKeys = [];
            //sort categories
            //return structured array
            setTree(transformTree(categoryList));

            categoriesOfItem.forEach(categoryId => {
                selectedKeys.push(getCategoryKeyById(categoryId));
            });
            setCheckedKeys(selectedKeys);
        }
    }, [categoryList, categoriesOfItem]);

    return (     
        <form>
            <div className='d-flex flex-row-reverse me-1'>
                <div className='btn btn-outline-secondary m-2' onClick={handleConfirm}>
                    <i className="bi bi-floppy h6"></i> 
                </div>
            </div>
            <Tree   treeData={categoryTree}
                    checkable 
                    defaultExpandAll
                    onCheck={onCheck}
                    checkedKeys={checkedKeys}
                    className='bg-light-grey'/>              
        </form>
    );
}

export default ItemCategoriesSelect;
